import apiMonitor from "@libs/monitor";
import { ApiResponse, create } from "apisauce";

const URL: string = process.env.NEXT_PUBLIC_DASHBOARD_API_URL as string;

export const createApiClient = () => {
  const api = create({
    baseURL: URL,
    headers: {
      "Content-Type": "application/json",
    },
    timeout: 15000,
  });

  api.addMonitor(apiMonitor);

  return api;
};

export const createDashboardClient = () => {
  const api = create({
    baseURL: process.env.NEXT_PUBLIC_DASHBOARD_URL,
    headers: {
      "Content-Type": "application/json",
    },
    timeout: 15000,
  });

  api.addMonitor(apiMonitor);

  return api;
};

export const createPaymentRequestClient = (secretKey: string) => {
  const api = create({
    baseURL: process.env.NEXT_PUBLIC_PAYMENT_REQUEST_URL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Basic ${Buffer.from(`${secretKey}:`).toString('base64')}`
    },
    timeout: 15000,
  });

  api.addMonitor(apiMonitor);

  return api;
};

export const createPaymentLinkClient = (secretKey: string) => {
  const api = create({
    baseURL: process.env.NEXT_PUBLIC_PAYMENT_LINK_URL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Basic ${Buffer.from(`${secretKey}:`).toString('base64')}`
    },
    timeout: 15000,
  });

  api.addMonitor(apiMonitor);

  return api;
};
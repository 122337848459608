export const getComparisonData = (): Promise<any> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        average: 34060.26666666667,
        currency: "php",
        data: [
          {
            start_time: 1620230400.0,
            end_time: 1620234000.0,
            value: 46806,
          },
          {
            start_time: 1620234000.0,
            end_time: 1620237600.0,
            value: 0,
          },
          {
            start_time: 1620237600.0,
            end_time: 1620241200.0,
            value: 3252,
          },
          {
            start_time: 1620241200.0,
            end_time: 1620244800.0,
            value: 0,
          },
          {
            start_time: 1620244800.0,
            end_time: 1620248400.0,
            value: 0,
          },
          {
            start_time: 1620248400.0,
            end_time: 1620252000.0,
            value: 253540,
          },
          {
            start_time: 1620252000.0,
            end_time: 1620255600.0,
            value: 0,
          },
          {
            start_time: 1620255600.0,
            end_time: 1620259200.0,
            value: 0,
          },
          {
            start_time: 1620259200.0,
            end_time: 1620262800.0,
            value: 0,
          },
          {
            start_time: 1620262800.0,
            end_time: 1620266400.0,
            value: 0,
          },
          {
            start_time: 1620266400.0,
            end_time: 1620270000.0,
            value: 28546,
          },
          {
            start_time: 1620270000.0,
            end_time: 1620273600.0,
            value: 8535,
          },
          {
            start_time: 1620273600.0,
            end_time: 1620277200.0,
            value: 22945,
          },
          {
            start_time: 1620277200.0,
            end_time: 1620280800.0,
            value: 21010,
          },
          {
            start_time: 1620280800.0,
            end_time: 1620284400.0,
            value: 10478,
          },
          {
            start_time: 1620284400.0,
            end_time: 1620288000.0,
            value: 4131,
          },
          {
            start_time: 1620288000.0,
            end_time: 1620291600.0,
            value: 0,
          },
          {
            start_time: 1620291600.0,
            end_time: 1620295200.0,
            value: 1192,
          },
          {
            start_time: 1620295200.0,
            end_time: 1620298800.0,
            value: 21152,
          },
          {
            start_time: 1620298800.0,
            end_time: 1620302400.0,
            value: 23539,
          },
          {
            start_time: 1620302400.0,
            end_time: 1620306000.0,
            value: 45295,
          },
          {
            start_time: 1620306000.0,
            end_time: 1620309600.0,
            value: 9632,
          },
          {
            start_time: 1620309600.0,
            end_time: 1620313200.0,
            value: 0,
          },
          {
            start_time: 1620313200.0,
            end_time: 1620316800.0,
            value: 10851,
          },
        ],
        estimated: false,
        total: 510904.0,
        unit: "hour",
      });
    }, 300);
  });
};

export const getTodayData = (): Promise<any> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        average: 19676.235294117647,
        currency: "php",
        data: [
          {
            start_time: 1622908800.0,
            end_time: 1622912400.0,
            value: 0,
          },
          {
            start_time: 1622912400.0,
            end_time: 1622916000.0,
            value: 0,
          },
          {
            start_time: 1622916000.0,
            end_time: 1622919600.0,
            value: 6879,
          },
          {
            start_time: 1622919600.0,
            end_time: 1622923200.0,
            value: 0,
          },
          {
            start_time: 1622923200.0,
            end_time: 1622926800.0,
            value: 0,
          },
          {
            start_time: 1622926800.0,
            end_time: 1622930400.0,
            value: 2787,
          },
          {
            start_time: 1622930400.0,
            end_time: 1622934000.0,
            value: 0,
          },
          {
            start_time: 1622934000.0,
            end_time: 1622937600.0,
            value: 0,
          },
          {
            start_time: 1622937600.0,
            end_time: 1622941200.0,
            value: 6088,
          },
          {
            start_time: 1622941200.0,
            end_time: 1622944800.0,
            value: 7675,
          },
          {
            start_time: 1622944800.0,
            end_time: 1622948400.0,
            value: 7345,
          },
          {
            start_time: 1622948400.0,
            end_time: 1622952000.0,
            value: 20233,
          },
          {
            start_time: 1622952000.0,
            end_time: 1622955600.0,
            value: 32773,
          },
          {
            start_time: 1622955600.0,
            end_time: 1622959200.0,
            value: 32327,
          },
          {
            start_time: 1622959200.0,
            end_time: 1622962800.0,
            value: 24827,
          },
          {
            start_time: 1622962800.0,
            end_time: 1622966400.0,
            value: 8243,
          },
          {
            start_time: 1622966400.0,
            end_time: 1622970000.0,
            value: 50852,
          },
          {
            start_time: 1622970000.0,
            end_time: 1622973600.0,
            value: 25838,
          },
          {
            start_time: 1622973600.0,
            end_time: 1622977200.0,
            value: 34569,
          },
          {
            start_time: 1622977200.0,
            end_time: 1622980800.0,
            value: 23323,
          },
          {
            start_time: 1622980800.0,
            end_time: 1622984400.0,
            value: 10920,
          },
          {
            start_time: 1622984400.0,
            end_time: 1622988000.0,
            value: 7089,
          },
          {
            start_time: 1622988000.0,
            end_time: 1622991600.0,
            value: 32728,
          },
        ],
        estimated: false,
        total: 334496.0,
        unit: "hour",
      });
    }, 300);
  });
};

export const getGrossVolumeData = (): Promise<any> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        average: 191406.6923076923,
        currency: "php",
        data: [
          {
            start_time: 1622476800.0,
            end_time: 1622563200.0,
            value: 211665,
          },
          {
            start_time: 1622563200.0,
            end_time: 1622649600.0,
            value: 127276,
          },
          {
            start_time: 1622649600.0,
            end_time: 1622736000.0,
            value: 349892,
          },
          {
            start_time: 1622736000.0,
            end_time: 1622822400.0,
            value: 167046,
          },
          {
            start_time: 1622822400.0,
            end_time: 1622908800.0,
            value: 227243,
          },
          {
            start_time: 1622908800.0,
            end_time: 1622995200.0,
            value: 347791,
          },
          {
            start_time: 1622995200.0,
            end_time: 1623081600.0,
            value: 160154,
          },
          {
            start_time: 1623081600.0,
            end_time: 1623168000.0,
            value: 101445,
          },
          {
            start_time: 1623168000.0,
            end_time: 1623254400.0,
            value: 129877,
          },
          {
            start_time: 1623254400.0,
            end_time: 1623340800.0,
            value: 153191,
          },
          {
            start_time: 1623340800.0,
            end_time: 1623427200.0,
            value: 95217,
          },
          {
            start_time: 1623427200.0,
            end_time: 1623513600.0,
            value: 153343,
          },
          {
            start_time: 1623513600.0,
            end_time: 1623600000.0,
            value: 264147,
          },
        ],
        estimated: false,
        total: 2488287.0,
        unit: "day",
      });
    }, 600);
  });
};

export const getGrossVolumeComparisonData = (): Promise<any> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        average: 293699.0,
        currency: "php",
        data: [
          {
            start_time: 1619798400.0,
            end_time: 1619884800.0,
            value: 234035,
          },
          {
            start_time: 1619884800.0,
            end_time: 1619971200.0,
            value: 255510,
          },
          {
            start_time: 1619971200.0,
            end_time: 1620057600.0,
            value: 299459,
          },
          {
            start_time: 1620057600.0,
            end_time: 1620144000.0,
            value: 243759,
          },
          {
            start_time: 1620144000.0,
            end_time: 1620230400.0,
            value: 601185,
          },
          {
            start_time: 1620230400.0,
            end_time: 1620316800.0,
            value: 510904,
          },
          {
            start_time: 1620316800.0,
            end_time: 1620403200.0,
            value: 200047,
          },
          {
            start_time: 1620403200.0,
            end_time: 1620489600.0,
            value: 133369,
          },
          {
            start_time: 1620489600.0,
            end_time: 1620576000.0,
            value: 152109,
          },
          {
            start_time: 1620576000.0,
            end_time: 1620662400.0,
            value: 250644,
          },
          {
            start_time: 1620662400.0,
            end_time: 1620748800.0,
            value: 279057,
          },
          {
            start_time: 1620748800.0,
            end_time: 1620835200.0,
            value: 318244,
          },
          {
            start_time: 1620835200.0,
            end_time: 1620921600.0,
            value: 339765,
          },
        ],
        estimated: false,
        total: 3818087.0,
        unit: "day",
      });
    }, 600);
  });
};

export const getNetVolumeData = (): Promise<any> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        average: 169521.07692307694,
        currency: "php",
        data: [
          {
            start_time: 1622476800.0,
            end_time: 1622563200.0,
            value: 197655,
          },
          {
            start_time: 1622563200.0,
            end_time: 1622649600.0,
            value: 122000,
          },
          {
            start_time: 1622649600.0,
            end_time: 1622736000.0,
            value: 239359,
          },
          {
            start_time: 1622736000.0,
            end_time: 1622822400.0,
            value: 133547,
          },
          {
            start_time: 1622822400.0,
            end_time: 1622908800.0,
            value: 218169,
          },
          {
            start_time: 1622908800.0,
            end_time: 1622995200.0,
            value: 329424,
          },
          {
            start_time: 1622995200.0,
            end_time: 1623081600.0,
            value: 133035,
          },
          {
            start_time: 1623081600.0,
            end_time: 1623168000.0,
            value: 96898,
          },
          {
            start_time: 1623168000.0,
            end_time: 1623254400.0,
            value: 124665,
          },
          {
            start_time: 1623254400.0,
            end_time: 1623340800.0,
            value: 146935,
          },
          {
            start_time: 1623340800.0,
            end_time: 1623427200.0,
            value: 91232,
          },
          {
            start_time: 1623427200.0,
            end_time: 1623513600.0,
            value: 126682,
          },
          {
            start_time: 1623513600.0,
            end_time: 1623600000.0,
            value: 244173,
          },
        ],
        estimated: false,
        total: 2203774.0,
        unit: "day",
      });
    }, 600);
  });
};

export const getNetVolumeComparisonData = (): Promise<any> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        average: 273470.0,
        currency: "php",
        data: [
          {
            start_time: 1619798400.0,
            end_time: 1619884800.0,
            value: 224230,
          },
          {
            start_time: 1619884800.0,
            end_time: 1619971200.0,
            value: 244793,
          },
          {
            start_time: 1619971200.0,
            end_time: 1620057600.0,
            value: 272318,
          },
          {
            start_time: 1620057600.0,
            end_time: 1620144000.0,
            value: 230545,
          },
          {
            start_time: 1620144000.0,
            end_time: 1620230400.0,
            value: 563591,
          },
          {
            start_time: 1620230400.0,
            end_time: 1620316800.0,
            value: 491240,
          },
          {
            start_time: 1620316800.0,
            end_time: 1620403200.0,
            value: 191898,
          },
          {
            start_time: 1620403200.0,
            end_time: 1620489600.0,
            value: 127836,
          },
          {
            start_time: 1620489600.0,
            end_time: 1620576000.0,
            value: 145991,
          },
          {
            start_time: 1620576000.0,
            end_time: 1620662400.0,
            value: 219833,
          },
          {
            start_time: 1620662400.0,
            end_time: 1620748800.0,
            value: 268021,
          },
          {
            start_time: 1620748800.0,
            end_time: 1620835200.0,
            value: 248549,
          },
          {
            start_time: 1620835200.0,
            end_time: 1620921600.0,
            value: 326265,
          },
        ],
        estimated: false,
        total: 3555110.0,
        unit: "day",
      });
    }, 600);
  });
};

export const getSuccessfulChargeCountData = (): Promise<any> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        average: 32.46153846153846,
        currency: "php",
        data: [
          {
            start_time: 1622476800.0,
            end_time: 1622563200.0,
            value: 20,
          },
          {
            start_time: 1622563200.0,
            end_time: 1622649600.0,
            value: 19,
          },
          {
            start_time: 1622649600.0,
            end_time: 1622736000.0,
            value: 49,
          },
          {
            start_time: 1622736000.0,
            end_time: 1622822400.0,
            value: 23,
          },
          {
            start_time: 1622822400.0,
            end_time: 1622908800.0,
            value: 27,
          },
          {
            start_time: 1622908800.0,
            end_time: 1622995200.0,
            value: 131,
          },
          {
            start_time: 1622995200.0,
            end_time: 1623081600.0,
            value: 15,
          },
          {
            start_time: 1623081600.0,
            end_time: 1623168000.0,
            value: 22,
          },
          {
            start_time: 1623168000.0,
            end_time: 1623254400.0,
            value: 16,
          },
          {
            start_time: 1623254400.0,
            end_time: 1623340800.0,
            value: 21,
          },
          {
            start_time: 1623340800.0,
            end_time: 1623427200.0,
            value: 15,
          },
          {
            start_time: 1623427200.0,
            end_time: 1623513600.0,
            value: 31,
          },
          {
            start_time: 1623513600.0,
            end_time: 1623600000.0,
            value: 33,
          },
        ],
        estimated: false,
        total: 422.0,
        unit: "day",
      });
    }, 600);
  });
};

export const getSuccessfulChargeCountComparisonData = (): Promise<any> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        average: 39.46153846153846,
        currency: "php",
        data: [
          {
            start_time: 1619798400.0,
            end_time: 1619884800.0,
            value: 37,
          },
          {
            start_time: 1619884800.0,
            end_time: 1619971200.0,
            value: 39,
          },
          {
            start_time: 1619971200.0,
            end_time: 1620057600.0,
            value: 74,
          },
          {
            start_time: 1620057600.0,
            end_time: 1620144000.0,
            value: 48,
          },
          {
            start_time: 1620144000.0,
            end_time: 1620230400.0,
            value: 83,
          },
          {
            start_time: 1620230400.0,
            end_time: 1620316800.0,
            value: 46,
          },
          {
            start_time: 1620316800.0,
            end_time: 1620403200.0,
            value: 27,
          },
          {
            start_time: 1620403200.0,
            end_time: 1620489600.0,
            value: 20,
          },
          {
            start_time: 1620489600.0,
            end_time: 1620576000.0,
            value: 19,
          },
          {
            start_time: 1620576000.0,
            end_time: 1620662400.0,
            value: 23,
          },
          {
            start_time: 1620662400.0,
            end_time: 1620748800.0,
            value: 31,
          },
          {
            start_time: 1620748800.0,
            end_time: 1620835200.0,
            value: 27,
          },
          {
            start_time: 1620835200.0,
            end_time: 1620921600.0,
            value: 39,
          },
        ],
        estimated: false,
        total: 513.0,
        unit: "day",
      });
    }, 600);
  });
};

export const getRequestVolumeData = (): Promise<any> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        average: 169521.07692307694,
        currency: "php",
        data: [
          {
            start_time: 1622476800.0,
            end_time: 1622563200.0,
            value: 197655,
          },
          {
            start_time: 1622563200.0,
            end_time: 1622649600.0,
            value: 122000,
          },
          {
            start_time: 1622649600.0,
            end_time: 1622736000.0,
            value: 239359,
          },
          {
            start_time: 1622736000.0,
            end_time: 1622822400.0,
            value: 133547,
          },
          {
            start_time: 1622822400.0,
            end_time: 1622908800.0,
            value: 218169,
          },
          {
            start_time: 1622908800.0,
            end_time: 1622995200.0,
            value: 329424,
          },
          {
            start_time: 1622995200.0,
            end_time: 1623081600.0,
            value: 133035,
          },
          {
            start_time: 1623081600.0,
            end_time: 1623168000.0,
            value: 96898,
          },
          {
            start_time: 1623168000.0,
            end_time: 1623254400.0,
            value: 124665,
          },
          {
            start_time: 1623254400.0,
            end_time: 1623340800.0,
            value: 146935,
          },
          {
            start_time: 1623340800.0,
            end_time: 1623427200.0,
            value: 91232,
          },
          {
            start_time: 1623427200.0,
            end_time: 1623513600.0,
            value: 126682,
          },
          {
            start_time: 1623513600.0,
            end_time: 1623600000.0,
            value: 244173,
          },
        ],
        estimated: false,
        total: 2203774.0,
        unit: "day",
      });
    }, 600);
  });
};

export const getRequestVolumeComparisonData = (): Promise<any> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        average: 273470.0,
        currency: "php",
        data: [
          {
            start_time: 1619798400.0,
            end_time: 1619884800.0,
            value: 224230,
          },
          {
            start_time: 1619884800.0,
            end_time: 1619971200.0,
            value: 244793,
          },
          {
            start_time: 1619971200.0,
            end_time: 1620057600.0,
            value: 272318,
          },
          {
            start_time: 1620057600.0,
            end_time: 1620144000.0,
            value: 230545,
          },
          {
            start_time: 1620144000.0,
            end_time: 1620230400.0,
            value: 563591,
          },
          {
            start_time: 1620230400.0,
            end_time: 1620316800.0,
            value: 491240,
          },
          {
            start_time: 1620316800.0,
            end_time: 1620403200.0,
            value: 191898,
          },
          {
            start_time: 1620403200.0,
            end_time: 1620489600.0,
            value: 127836,
          },
          {
            start_time: 1620489600.0,
            end_time: 1620576000.0,
            value: 145991,
          },
          {
            start_time: 1620576000.0,
            end_time: 1620662400.0,
            value: 219833,
          },
          {
            start_time: 1620662400.0,
            end_time: 1620748800.0,
            value: 268021,
          },
          {
            start_time: 1620748800.0,
            end_time: 1620835200.0,
            value: 248549,
          },
          {
            start_time: 1620835200.0,
            end_time: 1620921600.0,
            value: 326265,
          },
        ],
        estimated: false,
        total: 3555110.0,
        unit: "day",
      });
    }, 600);
  });
};

export const getLinksVolumeData = (): Promise<any> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        average: 169521.07692307694,
        currency: "php",
        data: [
          {
            start_time: 1622476800.0,
            end_time: 1622563200.0,
            value: 197655,
          },
          {
            start_time: 1622563200.0,
            end_time: 1622649600.0,
            value: 122000,
          },
          {
            start_time: 1622649600.0,
            end_time: 1622736000.0,
            value: 239359,
          },
          {
            start_time: 1622736000.0,
            end_time: 1622822400.0,
            value: 133547,
          },
          {
            start_time: 1622822400.0,
            end_time: 1622908800.0,
            value: 218169,
          },
          {
            start_time: 1622908800.0,
            end_time: 1622995200.0,
            value: 329424,
          },
          {
            start_time: 1622995200.0,
            end_time: 1623081600.0,
            value: 133035,
          },
          {
            start_time: 1623081600.0,
            end_time: 1623168000.0,
            value: 96898,
          },
          {
            start_time: 1623168000.0,
            end_time: 1623254400.0,
            value: 124665,
          },
          {
            start_time: 1623254400.0,
            end_time: 1623340800.0,
            value: 146935,
          },
          {
            start_time: 1623340800.0,
            end_time: 1623427200.0,
            value: 91232,
          },
          {
            start_time: 1623427200.0,
            end_time: 1623513600.0,
            value: 126682,
          },
          {
            start_time: 1623513600.0,
            end_time: 1623600000.0,
            value: 244173,
          },
        ],
        estimated: false,
        total: 2203774.0,
        unit: "day",
      });
    }, 600);
  });
};

export const getLinksVolumeComparisonData = (): Promise<any> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        average: 273470.0,
        currency: "php",
        data: [
          {
            start_time: 1619798400.0,
            end_time: 1619884800.0,
            value: 224230,
          },
          {
            start_time: 1619884800.0,
            end_time: 1619971200.0,
            value: 244793,
          },
          {
            start_time: 1619971200.0,
            end_time: 1620057600.0,
            value: 272318,
          },
          {
            start_time: 1620057600.0,
            end_time: 1620144000.0,
            value: 230545,
          },
          {
            start_time: 1620144000.0,
            end_time: 1620230400.0,
            value: 563591,
          },
          {
            start_time: 1620230400.0,
            end_time: 1620316800.0,
            value: 491240,
          },
          {
            start_time: 1620316800.0,
            end_time: 1620403200.0,
            value: 191898,
          },
          {
            start_time: 1620403200.0,
            end_time: 1620489600.0,
            value: 127836,
          },
          {
            start_time: 1620489600.0,
            end_time: 1620576000.0,
            value: 145991,
          },
          {
            start_time: 1620576000.0,
            end_time: 1620662400.0,
            value: 219833,
          },
          {
            start_time: 1620662400.0,
            end_time: 1620748800.0,
            value: 268021,
          },
          {
            start_time: 1620748800.0,
            end_time: 1620835200.0,
            value: 248549,
          },
          {
            start_time: 1620835200.0,
            end_time: 1620921600.0,
            value: 326265,
          },
        ],
        estimated: false,
        total: 3555110.0,
        unit: "day",
      });
    }, 600);
  });
};

export const getLinksPaymentsCountData = (): Promise<any> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        average: 32.46153846153846,
        currency: "php",
        data: [
          {
            start_time: 1622476800.0,
            end_time: 1622563200.0,
            value: 20,
          },
          {
            start_time: 1622563200.0,
            end_time: 1622649600.0,
            value: 19,
          },
          {
            start_time: 1622649600.0,
            end_time: 1622736000.0,
            value: 49,
          },
          {
            start_time: 1622736000.0,
            end_time: 1622822400.0,
            value: 23,
          },
          {
            start_time: 1622822400.0,
            end_time: 1622908800.0,
            value: 27,
          },
          {
            start_time: 1622908800.0,
            end_time: 1622995200.0,
            value: 28,
          },
          {
            start_time: 1622995200.0,
            end_time: 1623081600.0,
            value: 15,
          },
          {
            start_time: 1623081600.0,
            end_time: 1623168000.0,
            value: 22,
          },
          {
            start_time: 1623168000.0,
            end_time: 1623254400.0,
            value: 16,
          },
          {
            start_time: 1623254400.0,
            end_time: 1623340800.0,
            value: 21,
          },
          {
            start_time: 1623340800.0,
            end_time: 1623427200.0,
            value: 15,
          },
          {
            start_time: 1623427200.0,
            end_time: 1623513600.0,
            value: 31,
          },
          {
            start_time: 1623513600.0,
            end_time: 1623600000.0,
            value: 33,
          },
        ],
        estimated: false,
        total: 319.0,
        unit: "day",
      });
    }, 600);
  });
};

export const getLinksPaymentsCountComparisonData = (): Promise<any> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        average: 39.46153846153846,
        currency: "php",
        data: [
          {
            start_time: 1619798400.0,
            end_time: 1619884800.0,
            value: 37,
          },
          {
            start_time: 1619884800.0,
            end_time: 1619971200.0,
            value: 39,
          },
          {
            start_time: 1619971200.0,
            end_time: 1620057600.0,
            value: 34,
          },
          {
            start_time: 1620057600.0,
            end_time: 1620144000.0,
            value: 48,
          },
          {
            start_time: 1620144000.0,
            end_time: 1620230400.0,
            value: 53,
          },
          {
            start_time: 1620230400.0,
            end_time: 1620316800.0,
            value: 46,
          },
          {
            start_time: 1620316800.0,
            end_time: 1620403200.0,
            value: 27,
          },
          {
            start_time: 1620403200.0,
            end_time: 1620489600.0,
            value: 20,
          },
          {
            start_time: 1620489600.0,
            end_time: 1620576000.0,
            value: 19,
          },
          {
            start_time: 1620576000.0,
            end_time: 1620662400.0,
            value: 23,
          },
          {
            start_time: 1620662400.0,
            end_time: 1620748800.0,
            value: 31,
          },
          {
            start_time: 1620748800.0,
            end_time: 1620835200.0,
            value: 27,
          },
          {
            start_time: 1620835200.0,
            end_time: 1620921600.0,
            value: 39,
          },
        ],
        estimated: false,
        total: 443.0,
        unit: "day",
      });
    }, 600);
  });
};

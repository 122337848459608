import * as Sentry from '@sentry/nextjs'
import {
  ForgotPasswordPayloadType,
  Login2faPayloadType,
  LoginPayloadType,
  RegisterPayloadType,
  ResetPasswordPayloadType,
  VerifyEmailPayloadType,
} from "@custom-types/Auth"
import apiMonitor from "@libs/monitor";
import { ApiResponse, create } from "apisauce";
import { createApiClient, createDashboardClient } from "./client";
import URIs from "./uri";

export const fetchClientInfo = async () => {
  try {
    const api = create({ baseURL: "https://www.cloudflare.com" });
    const response = await api.get("/cdn-cgi/trace");

    if (response.ok) {
      const data: string = response.data as string;
      const arr: string[] = data.split("\n");
      //initialize with open parenthesis
      let jsonString = "{";
      arr.forEach((i, index) => {
        if (i.length > 0 && i.includes("=")) {
          const keyValue: string[] = i.split("=");
          if (keyValue.length === 2) {
            jsonString = jsonString.concat(
              `"${keyValue[0]}": "${keyValue[1]}",`
            );
          }
        }
      });

      //remove extra comma
      jsonString = jsonString.slice(0, -1);

      //add close parenthesis
      jsonString = jsonString.concat("}");

      return JSON.parse(jsonString);
    } else {
      return {
        ip: null,
        loc: null,
        uag: null,
      };
    }
  } catch (err: any) {
    Sentry.captureException(err);
    if (err.response) return err.response;
    return null;
  }
};

export const register = async (
  params: RegisterPayloadType
): Promise<ApiResponse<any, any>> => {
  const api = createApiClient();
  return await api.post(URIs.REGISTER, params);
};

export const login = async (
  params: LoginPayloadType
): Promise<ApiResponse<any, any>> => {
  const api = createDashboardClient();
  return await api.post(URIs.LOGIN, params);
};

export const login2fa = async (
  params: Login2faPayloadType
): Promise<ApiResponse<any, any>> => {
  const api = createDashboardClient();
  return await api.post(URIs.LOGIN_2FA, params);
};

export const loginResend = async (
  token: string
): Promise<ApiResponse<any, any>> => {
  const api = createApiClient();
  return await api.post(URIs.LOGIN_2FA_RESEND, { token });
};

export const logout = async (): Promise<ApiResponse<any, any>> => {
  const api = createDashboardClient();
  return await api.post(URIs.LOGOUT);
};

import { ApiResponse } from "apisauce";
import { PaymentsPayloadType } from "@custom-types/Payments";
import { createApiClient, createDashboardClient } from "./client";
import URIs, { DASHBOARD_API_KEY } from "./uri";

export const getPayments = async (
  token: string,
  params?: PaymentsPayloadType,
  url?: string,
): Promise<ApiResponse<any, any>> => {
  const api = createApiClient();
  if (url) {
    api.setBaseURL(url);
    return await api.get('', params, {
      headers: { Authorization: `Bearer ${token}` },
    });
  } else {
    return await api.get(URIs.PAYMENTS, params, {
      headers: { Authorization: `Bearer ${token}` },
    });
  }
};

export const exportPayments = async (
  token: string,
  recipients?: string[],
  status?: string,
  type?: string,
  from?: string,
  to?: string,
): Promise<ApiResponse<any, any>> => {
  const api = createApiClient();
  return await api.post(
    URIs.EXPORT_PAYMENTS,
    {
      recipients,
      status,
      type,
      created_at_from: from,
      created_at_to: to,
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    },
  )
}

export const getPaymentDetails = async (
  token: string,
  paymentId: string
): Promise<ApiResponse<any, any>> => {
  const api = createApiClient();
  return await api.get(
    URIs.PAYMENT.replace("$1", paymentId),
    {},
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

export const refundPayment = async (
  token: string,
  paymentId: string,
  amount: number,
  livemode: boolean,
  //reason?: string,
): Promise<ApiResponse<any, any>> => {
  const api = createApiClient();
  return await api.post(
    /* URIs.REFUND.replace("$1", paymentId),
    { amount, reason },
    {
      headers: { Authorization: `Bearer ${token}` },
    } */
    `${URIs.REFUND.replace("$1", paymentId)}?livemode=${livemode}`,
    { amount },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

export const voidPayment = async (
  token: string,
  paymentId: string,
): Promise<ApiResponse<any, any>> => {
  const api = createApiClient();
  return await api.post(
    URIs.VOID_PAYMENT.replace("$1", paymentId),
    {},
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

export const sendReceipt = async (
  token: string,
  to: string[],
  organization_id: string,
  charge_id: string,
  logo: string,
  name: string,
  website: string,
  support_email: string,
  support_phone: string,
): Promise<ApiResponse<any, any>> => {
  const api = createDashboardClient();
  return await api.post(
    URIs.SEND_RECEIPT,
    {
      token,
      to,
      organization_id,
      charge_id,
      merchant_logo: logo,
      merchant_name: name,
      merchant_website: website,
      merchant_support_email: support_email,
      merchant_support_phone: support_phone,
    },
    { headers: { Authorization: DASHBOARD_API_KEY } }
  );
};

export const sendRefundReceipt = async (
  token: string,
  to: string[],
  organization_id: string,
  charge_id: string,
  logo: string,
  name: string,
  website: string,
  support_email: string,
  support_phone: string,
): Promise<ApiResponse<any, any>> => {
  const api = createDashboardClient();
  return await api.post(
    URIs.SEND_REFUND_RECEIPT,
    {
      token,
      to,
      organization_id,
      charge_id,
      merchant_logo: logo,
      merchant_name: name,
      merchant_website: website,
      merchant_support_email: support_email,
      merchant_support_phone: support_phone,
    },
    { headers: { Authorization: DASHBOARD_API_KEY } }
  );
};

export const getPaymentEvents = (status: string) => {
  if (status === "succeeded") {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          object: "list",
          data: [
            {
              id: "evt_1IjpSeIoBfvEUBiw6dRL6st4",
              object: "event",
              api_version: "2019-03-14",
              created: 1619286007,
              data: {
                object: {
                  id: "ch_1IjpSaIoBfvEUBiwtPF2Ryoc",
                  object: "charge",
                  amount: 486625,
                  amount_captured: 486625,
                  amount_refunded: 0,
                  application: null,
                  application_fee: null,
                  application_fee_amount: null,
                  balance_transaction: "txn_1IjpSdIoBfvEUBiw6bPAgNQy",
                  billing_details: {
                    address: {
                      city: null,
                      country: null,
                      line1: null,
                      line2: null,
                      postal_code: null,
                      state: null,
                    },
                    email: null,
                    name: "NINA PUYAT",
                    phone: null,
                  },
                  calculated_statement_descriptor: "MAGPIE.IM",
                  captured: true,
                  created: 1619286004,
                  currency: "php",
                  customer: null,
                  description: null,
                  destination: null,
                  dispute: null,
                  disputed: false,
                  failure_code: null,
                  failure_message: null,
                  fraud_details: {},
                  invoice: null,
                  livemode: true,
                  metadata: {
                    connect_agent: "Spreedly",
                    order_id: "MqgpoyDwBjXzPzld5v0fSppnri6",
                  },
                  on_behalf_of: null,
                  order: null,
                  outcome: {
                    network_status: "approved_by_network",
                    reason: null,
                    risk_level: "normal",
                    seller_message: "Payment complete.",
                    type: "authorized",
                  },
                  paid: true,
                  payment_intent: null,
                  payment_method: "src_1IjpRlIoBfvEUBiwPq2AmWox",
                  payment_method_details: {
                    card: {
                      brand: "mastercard",
                      checks: {
                        address_line1_check: null,
                        address_postal_code_check: null,
                        cvc_check: "pass",
                      },
                      country: "PH",
                      exp_month: 3,
                      exp_year: 2023,
                      fingerprint: "CzdHmPNaoknay23e",
                      funding: "credit",
                      installments: null,
                      last4: "0238",
                      network: "mastercard",
                      three_d_secure: {
                        authenticated: true,
                        authentication_flow: "challenge",
                        result: "authenticated",
                        result_reason: null,
                        succeeded: true,
                        version: "1.0.2",
                      },
                      wallet: null,
                    },
                    type: "card",
                  },
                  receipt_email: null,
                  receipt_number: null,
                  receipt_url:
                    "https://pay.stripe.com/receipts/acct_173PceIoBfvEUBiw/ch_1IjpSaIoBfvEUBiwtPF2Ryoc/rcpt_JMYZpsnii4rm09S6lzBFrjxPn2mfDNs",
                  refunded: false,
                  refunds: {
                    object: "list",
                    data: [],
                    has_more: false,
                    total_count: 0,
                    url: "/v1/charges/ch_1IjpSaIoBfvEUBiwtPF2Ryoc/refunds",
                  },
                  review: null,
                  shipping: null,
                  source: {
                    id: "src_1IjpRmIoBfvEUBiwZVLOzUWU",
                    object: "source",
                    amount: 486625,
                    client_secret: "src_client_secret_pnuEZC9ehzzIdp7XfUMv4dV7",
                    created: 1619286007,
                    currency: "php",
                    flow: "redirect",
                    livemode: true,
                    metadata: {},
                    owner: {
                      address: {
                        city: null,
                        country: null,
                        line1: null,
                        line2: null,
                        postal_code: null,
                        state: null,
                      },
                      email: null,
                      name: "NINA PUYAT",
                      phone: null,
                      verified_address: null,
                      verified_email: null,
                      verified_name: null,
                      verified_phone: null,
                    },
                    redirect: {
                      failure_reason: null,
                      return_url:
                        "https://core.spreedly.com/transaction/MqgpoyDwBjXzPzld5v0fSppnri6/redirect",
                      status: "succeeded",
                      url: "https://hooks.stripe.com/redirect/authenticate/src_1IjpRmIoBfvEUBiwZVLOzUWU?client_secret=src_client_secret_pnuEZC9ehzzIdp7XfUMv4dV7\u0026source_redirect_slug=live_YWNjdF8xNzNQY2VJb0JmdkVVQml3LF9KTVlZYm5MODd1bHVRMGJTQk9pOUFGd0JXZE5mbExh0100JPyStbHQ",
                    },
                    statement_descriptor: null,
                    status: "consumed",
                    three_d_secure: {
                      card: "src_1IjpRlIoBfvEUBiwPq2AmWox",
                      exp_month: 3,
                      exp_year: 2023,
                      last4: "0238",
                      country: "PH",
                      brand: "MasterCard",
                      cvc_check: "pass",
                      funding: "credit",
                      fingerprint: "CzdHmPNaoknay23e",
                      three_d_secure: "optional",
                      authenticated: true,
                      customer: null,
                      name: null,
                      address_line1_check: null,
                      address_zip_check: null,
                      tokenization_method: null,
                      dynamic_last4: null,
                    },
                    type: "three_d_secure",
                    usage: "single_use",
                  },
                  source_transfer: null,
                  statement_descriptor: null,
                  statement_descriptor_suffix: null,
                  status: "succeeded",
                  transfer_data: null,
                  transfer_group: null,
                },
              },
              is_object_log: false,
              is_summarized: false,
              is_unstable_event: false,
              livemode: true,
              pending_webhooks: 0,
              request: {
                id: "req_UWFTOSnVRP4dmJ",
                idempotency_key: "MqgpoyDwBjXzPzld5v0fSppnri6",
              },
              type: "charge.succeeded",
              webhooks_archived: false,
            },
          ],
          has_more: false,
          url: "/v1/events",
        });
      }, 300);
    });
  } else if (status === "failed") {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          object: "list",
          data: [
            {
              id: "evt_1IjioRIoBfvEUBiwroJdZ0nG",
              object: "event",
              api_version: "2019-03-14",
              created: 1619260450,
              data: {
                object: {
                  id: "ch_1IjioNIoBfvEUBiwjep6BeWM",
                  object: "charge",
                  amount: 225000,
                  amount_captured: 0,
                  amount_refunded: 0,
                  application: null,
                  application_fee: null,
                  application_fee_amount: null,
                  balance_transaction: null,
                  billing_details: {
                    address: {
                      city: null,
                      country: null,
                      line1: null,
                      line2: null,
                      postal_code: null,
                      state: null,
                    },
                    email: null,
                    name: "Rap Molina",
                    phone: null,
                  },
                  calculated_statement_descriptor: "DIBZTECH INC.",
                  captured: false,
                  created: 1619260447,
                  currency: "php",
                  customer: null,
                  description: "Magpie load Dibz wallet",
                  destination: null,
                  dispute: null,
                  disputed: false,
                  failure_code: "card_declined",
                  failure_message: "Your card was declined.",
                  fraud_details: {},
                  invoice: null,
                  livemode: true,
                  metadata: {
                    connect_agent: "Spreedly",
                    order_id: "DQEMJqKdmnG1H2FQiPL3R0CrtBI",
                  },
                  on_behalf_of: null,
                  order: null,
                  outcome: {
                    network_status: "declined_by_network",
                    reason: "do_not_honor",
                    risk_level: "normal",
                    seller_message:
                      "The bank returned the decline code `do_not_honor`.",
                    type: "issuer_declined",
                  },
                  paid: false,
                  payment_intent: null,
                  payment_method: "card_1IjioNIoBfvEUBiw6thaFOQO",
                  payment_method_details: {
                    card: {
                      brand: "amex",
                      checks: {
                        address_line1_check: null,
                        address_postal_code_check: null,
                        cvc_check: null,
                      },
                      country: "PH",
                      exp_month: 5,
                      exp_year: 2024,
                      fingerprint: "MUPuzCLpttOLt4aj",
                      funding: "credit",
                      installments: null,
                      last4: "7084",
                      network: "amex",
                      three_d_secure: null,
                      wallet: null,
                    },
                    type: "card",
                  },
                  receipt_email: null,
                  receipt_number: null,
                  receipt_url: null,
                  refunded: false,
                  refunds: {
                    object: "list",
                    data: [],
                    has_more: false,
                    total_count: 0,
                    url: "/v1/charges/ch_1IjioNIoBfvEUBiwjep6BeWM/refunds",
                  },
                  review: null,
                  shipping: null,
                  source: {
                    id: "card_1IjioNIoBfvEUBiw6thaFOQO",
                    object: "card",
                    address_city: null,
                    address_country: null,
                    address_line1: null,
                    address_line1_check: null,
                    address_line2: null,
                    address_state: null,
                    address_zip: null,
                    address_zip_check: null,
                    brand: "American Express",
                    country: "PH",
                    customer: null,
                    cvc_check: null,
                    dynamic_last4: null,
                    exp_month: 5,
                    exp_year: 2024,
                    fingerprint: "MUPuzCLpttOLt4aj",
                    funding: "credit",
                    last4: "7084",
                    metadata: {},
                    name: "Rap Molina",
                    tokenization_method: null,
                  },
                  source_transfer: null,
                  statement_descriptor: "Dibztech Inc.",
                  statement_descriptor_suffix: null,
                  status: "failed",
                  transfer_data: null,
                  transfer_group: null,
                },
              },
              is_object_log: false,
              is_summarized: false,
              is_unstable_event: false,
              livemode: true,
              pending_webhooks: 0,
              request: {
                id: "req_2udDOR9ADI8nIT",
                idempotency_key: null,
              },
              type: "charge.failed",
              webhooks_archived: false,
            },
          ],
          has_more: false,
          url: "/v1/events",
        });
      }, 300);
    });
  } else if (status === "uncaptured") {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          object: "list",
          data: [
            {
              id: "evt_1IjipjIoBfvEUBiwcRDGfVKx",
              object: "event",
              api_version: "2019-03-14",
              created: 1619260531,
              data: {
                object: {
                  id: "ch_1IjipiIoBfvEUBiwTbVq2jgW",
                  object: "charge",
                  amount: 3000,
                  amount_captured: 0,
                  amount_refunded: 0,
                  application: null,
                  application_fee: null,
                  application_fee_amount: null,
                  balance_transaction: null,
                  billing_details: {
                    address: {
                      city: null,
                      country: null,
                      line1: null,
                      line2: null,
                      postal_code: null,
                      state: null,
                    },
                    email: null,
                    name: "Dominick Danao",
                    phone: null,
                  },
                  calculated_statement_descriptor: "MAGPIE.IM",
                  captured: false,
                  created: 1619260530,
                  currency: "php",
                  customer: null,
                  description: null,
                  destination: null,
                  dispute: null,
                  disputed: false,
                  failure_code: null,
                  failure_message: null,
                  fraud_details: {},
                  invoice: null,
                  livemode: true,
                  metadata: {
                    connect_agent: "Spreedly",
                    order_id: "QIjzaOQBdYSweIMDaQgoSKSVIsf",
                  },
                  on_behalf_of: null,
                  order: null,
                  outcome: {
                    network_status: "approved_by_network",
                    reason: null,
                    risk_level: "normal",
                    seller_message: "Payment complete.",
                    type: "authorized",
                  },
                  paid: true,
                  payment_intent: null,
                  payment_method: "src_1Ijip8IoBfvEUBiwfnIAhhY6",
                  payment_method_details: {
                    card: {
                      brand: "mastercard",
                      checks: {
                        address_line1_check: null,
                        address_postal_code_check: null,
                        cvc_check: "pass",
                      },
                      country: "PH",
                      exp_month: 3,
                      exp_year: 2024,
                      fingerprint: "faOth6prfJ331Cjm",
                      funding: "credit",
                      installments: null,
                      last4: "0455",
                      network: "mastercard",
                      three_d_secure: {
                        authenticated: true,
                        authentication_flow: "challenge",
                        result: "authenticated",
                        result_reason: null,
                        succeeded: true,
                        version: "1.0.2",
                      },
                      wallet: null,
                    },
                    type: "card",
                  },
                  receipt_email: null,
                  receipt_number: null,
                  receipt_url:
                    "https://pay.stripe.com/receipts/acct_173PceIoBfvEUBiw/ch_1IjipiIoBfvEUBiwTbVq2jgW/rcpt_JMRjoE3DElkf2Xax08E37UEhecqCuDC",
                  refunded: false,
                  refunds: {
                    object: "list",
                    data: [],
                    has_more: false,
                    total_count: 0,
                    url: "/v1/charges/ch_1IjipiIoBfvEUBiwTbVq2jgW/refunds",
                  },
                  review: null,
                  shipping: null,
                  source: {
                    id: "src_1Ijip9IoBfvEUBiwBtenf3eF",
                    object: "source",
                    amount: 3000,
                    client_secret: "src_client_secret_dtzWcSjrLyJRLvaYYZ1Artii",
                    created: 1619260531,
                    currency: "php",
                    flow: "redirect",
                    livemode: true,
                    metadata: {},
                    owner: {
                      address: {
                        city: null,
                        country: null,
                        line1: null,
                        line2: null,
                        postal_code: null,
                        state: null,
                      },
                      email: null,
                      name: "Dominick Danao",
                      phone: null,
                      verified_address: null,
                      verified_email: null,
                      verified_name: null,
                      verified_phone: null,
                    },
                    redirect: {
                      failure_reason: null,
                      return_url:
                        "https://core.spreedly.com/transaction/QIjzaOQBdYSweIMDaQgoSKSVIsf/redirect",
                      status: "succeeded",
                      url: "https://hooks.stripe.com/redirect/authenticate/src_1Ijip9IoBfvEUBiwBtenf3eF?client_secret=src_client_secret_dtzWcSjrLyJRLvaYYZ1Artii\u0026source_redirect_slug=live_YWNjdF8xNzNQY2VJb0JmdkVVQml3LF9KTVJpTW5OdVlzYTJRcTZiMzNoSHpXRWhRUm1aZG810100IvGnfuYD",
                    },
                    statement_descriptor: null,
                    status: "consumed",
                    three_d_secure: {
                      card: "src_1Ijip8IoBfvEUBiwfnIAhhY6",
                      exp_month: 3,
                      exp_year: 2024,
                      last4: "0455",
                      country: "PH",
                      brand: "MasterCard",
                      cvc_check: "pass",
                      funding: "credit",
                      fingerprint: "faOth6prfJ331Cjm",
                      three_d_secure: "optional",
                      authenticated: true,
                      customer: null,
                      name: null,
                      address_line1_check: null,
                      address_zip_check: null,
                      tokenization_method: null,
                      dynamic_last4: null,
                    },
                    type: "three_d_secure",
                    usage: "single_use",
                  },
                  source_transfer: null,
                  statement_descriptor: null,
                  statement_descriptor_suffix: null,
                  status: "succeeded",
                  transfer_data: null,
                  transfer_group: null,
                },
              },
              is_object_log: false,
              is_summarized: false,
              is_unstable_event: false,
              livemode: true,
              pending_webhooks: 0,
              request: {
                id: "req_I25HUSHf18Y3fa",
                idempotency_key: "QIjzaOQBdYSweIMDaQgoSKSVIsf",
              },
              type: "charge.succeeded",
              webhooks_archived: false,
            },
          ],
          has_more: false,
          url: "/v1/events",
        });
      }, 300);
    });
  } else if (status === "refunded") {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          object: "list",
          data: [
            {
              id: "evt_1IjimxIoBfvEUBiwet0B0w04",
              object: "event",
              api_version: "2019-03-14",
              created: 1619260359,
              data: {
                object: {
                  id: "ch_1IjimtIoBfvEUBiwmkQTu5UR",
                  object: "charge",
                  amount: 5000,
                  amount_captured: 5000,
                  amount_refunded: 5000,
                  application: null,
                  application_fee: null,
                  application_fee_amount: null,
                  balance_transaction: "txn_1IjimuIoBfvEUBiwuTJ3JDw6",
                  billing_details: {
                    address: {
                      city: null,
                      country: null,
                      line1: null,
                      line2: null,
                      postal_code: null,
                      state: null,
                    },
                    email: null,
                    name: "Rap Molina",
                    phone: null,
                  },
                  calculated_statement_descriptor: "DIBZTECH INC.",
                  captured: true,
                  created: 1619260355,
                  currency: "php",
                  customer: null,
                  description: "Charge 50php. This will be refunded.",
                  destination: null,
                  dispute: null,
                  disputed: false,
                  failure_code: null,
                  failure_message: null,
                  fraud_details: {},
                  invoice: null,
                  livemode: true,
                  metadata: {
                    connect_agent: "Spreedly",
                    order_id: "HQwQWX6bg1CBQ58qAEecNByqDen",
                  },
                  on_behalf_of: null,
                  order: null,
                  outcome: {
                    network_status: "approved_by_network",
                    reason: null,
                    risk_level: "normal",
                    seller_message: "Payment complete.",
                    type: "authorized",
                  },
                  paid: true,
                  payment_intent: null,
                  payment_method: "card_1IjimtIoBfvEUBiwCT4paZTf",
                  payment_method_details: {
                    card: {
                      brand: "mastercard",
                      checks: {
                        address_line1_check: null,
                        address_postal_code_check: null,
                        cvc_check: "pass",
                      },
                      country: "PH",
                      exp_month: 9,
                      exp_year: 2026,
                      fingerprint: "MUVwFSDYD0l3ZatT",
                      funding: "debit",
                      installments: null,
                      last4: "4284",
                      network: "mastercard",
                      three_d_secure: null,
                      wallet: null,
                    },
                    type: "card",
                  },
                  receipt_email: null,
                  receipt_number: null,
                  receipt_url:
                    "https://pay.stripe.com/receipts/acct_173PceIoBfvEUBiw/ch_1IjimtIoBfvEUBiwmkQTu5UR/rcpt_JMRgX2EIIwgYeyo6o2sDhMh2CjGqT1C",
                  refunded: true,
                  refunds: {
                    object: "list",
                    data: [
                      {
                        id: "re_1IjimxIoBfvEUBiwfCVoQ1y8",
                        object: "refund",
                        amount: 5000,
                        balance_transaction: "txn_1IjimxIoBfvEUBiwf7EIe2PU",
                        charge: "ch_1IjimtIoBfvEUBiwmkQTu5UR",
                        created: 1619260359,
                        currency: "php",
                        metadata: {
                          connect_agent: "Spreedly",
                        },
                        payment_intent: null,
                        reason: null,
                        receipt_number: null,
                        source_transfer_reversal: null,
                        status: "succeeded",
                        transfer_reversal: null,
                      },
                    ],
                    has_more: false,
                    total_count: 1,
                    url: "/v1/charges/ch_1IjimtIoBfvEUBiwmkQTu5UR/refunds",
                  },
                  review: null,
                  shipping: null,
                  source: {
                    id: "card_1IjimtIoBfvEUBiwCT4paZTf",
                    object: "card",
                    address_city: null,
                    address_country: null,
                    address_line1: null,
                    address_line1_check: null,
                    address_line2: null,
                    address_state: null,
                    address_zip: null,
                    address_zip_check: null,
                    brand: "MasterCard",
                    country: "PH",
                    customer: null,
                    cvc_check: "pass",
                    dynamic_last4: null,
                    exp_month: 9,
                    exp_year: 2026,
                    fingerprint: "MUVwFSDYD0l3ZatT",
                    funding: "debit",
                    last4: "4284",
                    metadata: {},
                    name: "Rap Molina",
                    tokenization_method: null,
                  },
                  source_transfer: null,
                  statement_descriptor: "Dibztech Inc.",
                  statement_descriptor_suffix: null,
                  status: "succeeded",
                  transfer_data: null,
                  transfer_group: null,
                },
                previous_attributes: {
                  amount_refunded: 0,
                  refunded: false,
                  refunds: {
                    data: [],
                    total_count: 0,
                  },
                },
              },
              is_object_log: false,
              is_summarized: false,
              is_unstable_event: false,
              livemode: true,
              pending_webhooks: 0,
              request: {
                id: "req_Wysz7RKsTXc8CY",
                idempotency_key: null,
              },
              type: "charge.refunded",
              webhooks_archived: false,
            },
            {
              id: "evt_1IjimvIoBfvEUBiw3UKkv5rr",
              object: "event",
              api_version: "2019-03-14",
              created: 1619260357,
              data: {
                object: {
                  id: "ch_1IjimtIoBfvEUBiwmkQTu5UR",
                  object: "charge",
                  amount: 5000,
                  amount_captured: 5000,
                  amount_refunded: 0,
                  application: null,
                  application_fee: null,
                  application_fee_amount: null,
                  balance_transaction: "txn_1IjimuIoBfvEUBiwuTJ3JDw6",
                  billing_details: {
                    address: {
                      city: null,
                      country: null,
                      line1: null,
                      line2: null,
                      postal_code: null,
                      state: null,
                    },
                    email: null,
                    name: "Rap Molina",
                    phone: null,
                  },
                  calculated_statement_descriptor: "DIBZTECH INC.",
                  captured: true,
                  created: 1619260355,
                  currency: "php",
                  customer: null,
                  description: "Charge 50php. This will be refunded.",
                  destination: null,
                  dispute: null,
                  disputed: false,
                  failure_code: null,
                  failure_message: null,
                  fraud_details: {},
                  invoice: null,
                  livemode: true,
                  metadata: {
                    connect_agent: "Spreedly",
                    order_id: "HQwQWX6bg1CBQ58qAEecNByqDen",
                  },
                  on_behalf_of: null,
                  order: null,
                  outcome: {
                    network_status: "approved_by_network",
                    reason: null,
                    risk_level: "normal",
                    seller_message: "Payment complete.",
                    type: "authorized",
                  },
                  paid: true,
                  payment_intent: null,
                  payment_method: "card_1IjimtIoBfvEUBiwCT4paZTf",
                  payment_method_details: {
                    card: {
                      brand: "mastercard",
                      checks: {
                        address_line1_check: null,
                        address_postal_code_check: null,
                        cvc_check: "pass",
                      },
                      country: "PH",
                      exp_month: 9,
                      exp_year: 2026,
                      fingerprint: "MUVwFSDYD0l3ZatT",
                      funding: "debit",
                      installments: null,
                      last4: "4284",
                      network: "mastercard",
                      three_d_secure: null,
                      wallet: null,
                    },
                    type: "card",
                  },
                  receipt_email: null,
                  receipt_number: null,
                  receipt_url:
                    "https://pay.stripe.com/receipts/acct_173PceIoBfvEUBiw/ch_1IjimtIoBfvEUBiwmkQTu5UR/rcpt_JMRgX2EIIwgYeyo6o2sDhMh2CjGqT1C",
                  refunded: false,
                  refunds: {
                    object: "list",
                    data: [],
                    has_more: false,
                    total_count: 0,
                    url: "/v1/charges/ch_1IjimtIoBfvEUBiwmkQTu5UR/refunds",
                  },
                  review: null,
                  shipping: null,
                  source: {
                    id: "card_1IjimtIoBfvEUBiwCT4paZTf",
                    object: "card",
                    address_city: null,
                    address_country: null,
                    address_line1: null,
                    address_line1_check: null,
                    address_line2: null,
                    address_state: null,
                    address_zip: null,
                    address_zip_check: null,
                    brand: "MasterCard",
                    country: "PH",
                    customer: null,
                    cvc_check: "pass",
                    dynamic_last4: null,
                    exp_month: 9,
                    exp_year: 2026,
                    fingerprint: "MUVwFSDYD0l3ZatT",
                    funding: "debit",
                    last4: "4284",
                    metadata: {},
                    name: "Rap Molina",
                    tokenization_method: null,
                  },
                  source_transfer: null,
                  statement_descriptor: "Dibztech Inc.",
                  statement_descriptor_suffix: null,
                  status: "succeeded",
                  transfer_data: null,
                  transfer_group: null,
                },
              },
              is_object_log: false,
              is_summarized: false,
              is_unstable_event: false,
              livemode: true,
              pending_webhooks: 0,
              request: {
                id: "req_Seo3XJf7LFMB0I",
                idempotency_key: null,
              },
              type: "charge.succeeded",
              webhooks_archived: false,
            },
          ],
          has_more: false,
          url: "/v1/events",
        });
      }, 300);
    });
  } else {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        reject({
          error: {
            code: "resource_missing",
            doc_url: "https://stripe.com/docs/error-codes/resource-missing",
            message: "No such charge: 'ch_1IjimtIoBfvEUBiwmkQTasd'",
            message_code: "missing_resource_livemode",
            param: "id",
            type: "invalid_request_error",
          },
        });
      }, 300);
    });
  }
};

export const getPaymentReceiptEmailHistory = (status: string) => {
  if (status === "succeeded" || status === "refunded") {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          object: "list",
          count: 2,
          data: [
            {
              created: 1619391754,
              email: "jerick@xpens.io",
              record_type: "Payment",
            },
            {
              created: 1619391142,
              email: "jerick@magpie.im",
              record_type: "Payment",
            },
          ],
          has_more: false,
          url: "/ajax/emails/receipt_email_history",
        });
      }, 300);
    });
  } else {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          object: "list",
          count: 0,
          data: [],
          has_more: false,
          url: "/ajax/emails/receipt_email_history",
        });
      }, 300);
    });
  }
};

export const getPaymentLogs = (status: string) => {
  if (status === "succeeded") {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          data: [
            {
              id: "req_UWFTOSnVRP4dmJ",
              application: null,
              created: 1619286004,
              livemode: true,
              objects: null,
              request: {
                livemode: true,
                method: "POST",
                url: "/v1/charges",
                get_params: {},
                post_params: {
                  source: "src_1IjpRmIoBfvEUBiwZVLOzUWU",
                  amount: "486625",
                  currency: "php",
                  ip: "127.0.0.1",
                  external_id: "MqgpoyDwBjXzPzld5v0fSppnri6",
                  payment_user_agent:
                    "Stripe/v1 ActiveMerchantBindings/1.119.0",
                  metadata: {
                    connect_agent: "Spreedly",
                    order_id: "MqgpoyDwBjXzPzld5v0fSppnri6",
                  },
                },
                query_string: "",
                request_body:
                  "source=src_1IjpRmIoBfvEUBiwZVLOzUWU\u0026amount=486625\u0026currency=php\u0026ip=127.0.0.1\u0026external_id=MqgpoyDwBjXzPzld5v0fSppnri6\u0026payment_user_agent=Stripe%2Fv1+ActiveMerchantBindings%2F1.119.0\u0026metadata[connect_agent]=Spreedly\u0026metadata[order_id]=MqgpoyDwBjXzPzld5v0fSppnri6",
                ip: "18.211.189.236",
                headers: {
                  "User-Agent": "Stripe/v1 ActiveMerchantBindings/1.119.0",
                  "Stripe-Version": "2015-04-07",
                  "X-Tls-Version": "TLSv1.2",
                },
                client_ip: "127.0.0.1",
                from_dashboard: false,
                from_dashboard_user: null,
                origin: null,
              },
              response: {
                status: 200,
                headers: {
                  "Content-Type": "application/json",
                  "Stripe-Version": "2015-04-07",
                  "Idempotency-Key": "MqgpoyDwBjXzPzld5v0fSppnri6",
                  "Original-Request": "req_UWFTOSnVRP4dmJ",
                  "Content-Length": "4355",
                  "Stripe-Should-Retry": "false",
                  "Request-Id": "req_UWFTOSnVRP4dmJ",
                },
                body: {
                  id: "ch_1IjpSaIoBfvEUBiwtPF2Ryoc",
                  object: "charge",
                  amount: 486625,
                  amount_captured: 486625,
                  amount_refunded: 0,
                  application: null,
                  application_fee: null,
                  application_fee_amount: null,
                  balance_transaction: "txn_1IjpSdIoBfvEUBiw6bPAgNQy",
                  billing_details: {
                    address: {
                      city: null,
                      country: null,
                      line1: null,
                      line2: null,
                      postal_code: null,
                      state: null,
                    },
                    email: null,
                    name: "NINA PUYAT",
                    phone: null,
                  },
                  calculated_statement_descriptor: "MAGPIE.IM",
                  captured: true,
                  created: 1619286004,
                  currency: "php",
                  customer: null,
                  description: null,
                  destination: null,
                  dispute: null,
                  disputed: false,
                  failure_code: null,
                  failure_message: null,
                  fraud_details: {},
                  invoice: null,
                  livemode: true,
                  metadata: {
                    connect_agent: "Spreedly",
                    order_id: "MqgpoyDwBjXzPzld5v0fSppnri6",
                  },
                  on_behalf_of: null,
                  order: null,
                  outcome: {
                    network_status: "approved_by_network",
                    reason: null,
                    risk_level: "normal",
                    seller_message: "Payment complete.",
                    type: "authorized",
                  },
                  paid: true,
                  payment_intent: null,
                  payment_method: "src_1IjpRlIoBfvEUBiwPq2AmWox",
                  payment_method_details: {
                    card: {
                      brand: "mastercard",
                      checks: {
                        address_line1_check: null,
                        address_postal_code_check: null,
                        cvc_check: "pass",
                      },
                      country: "PH",
                      exp_month: 3,
                      exp_year: 2023,
                      fingerprint: "CzdHmPNaoknay23e",
                      funding: "credit",
                      installments: null,
                      last4: "0238",
                      network: "mastercard",
                      three_d_secure: {
                        authenticated: true,
                        authentication_flow: "challenge",
                        result: "authenticated",
                        result_reason: null,
                        succeeded: true,
                        version: "1.0.2",
                      },
                      wallet: null,
                    },
                    type: "card",
                  },
                  receipt_email: null,
                  receipt_number: null,
                  receipt_url:
                    "https://pay.stripe.com/receipts/acct_173PceIoBfvEUBiw/ch_1IjpSaIoBfvEUBiwtPF2Ryoc/rcpt_JMYZpsnii4rm09S6lzBFrjxPn2mfDNs",
                  refunded: false,
                  refunds: {
                    object: "list",
                    data: [],
                    has_more: false,
                    total_count: 0,
                    url: "/v1/charges/ch_1IjpSaIoBfvEUBiwtPF2Ryoc/refunds",
                  },
                  review: null,
                  shipping: null,
                  source: {
                    id: "src_1IjpRmIoBfvEUBiwZVLOzUWU",
                    object: "source",
                    amount: 486625,
                    client_secret: "src_client_secret_pnuEZC9ehzzIdp7XfUMv4dV7",
                    created: 1619286007,
                    currency: "php",
                    flow: "redirect",
                    livemode: true,
                    metadata: {},
                    owner: {
                      address: {
                        city: null,
                        country: null,
                        line1: null,
                        line2: null,
                        postal_code: null,
                        state: null,
                      },
                      email: null,
                      name: "NINA PUYAT",
                      phone: null,
                      verified_address: null,
                      verified_email: null,
                      verified_name: null,
                      verified_phone: null,
                    },
                    redirect: {
                      failure_reason: null,
                      return_url:
                        "https://core.spreedly.com/transaction/MqgpoyDwBjXzPzld5v0fSppnri6/redirect",
                      status: "succeeded",
                      url: "https://hooks.stripe.com/redirect/authenticate/src_1IjpRmIoBfvEUBiwZVLOzUWU?client_secret=src_client_secret_pnuEZC9ehzzIdp7XfUMv4dV7\u0026source_redirect_slug=live_YWNjdF8xNzNQY2VJb0JmdkVVQml3LF9KTVlZYm5MODd1bHVRMGJTQk9pOUFGd0JXZE5mbExh0100JPyStbHQ",
                    },
                    statement_descriptor: null,
                    status: "consumed",
                    three_d_secure: {
                      card: "src_1IjpRlIoBfvEUBiwPq2AmWox",
                      exp_month: 3,
                      exp_year: 2023,
                      last4: "0238",
                      country: "PH",
                      brand: "MasterCard",
                      cvc_check: "pass",
                      funding: "credit",
                      fingerprint: "CzdHmPNaoknay23e",
                      three_d_secure: "optional",
                      authenticated: true,
                      customer: null,
                      name: null,
                      address_line1_check: null,
                      address_zip_check: null,
                      tokenization_method: null,
                      dynamic_last4: null,
                    },
                    type: "three_d_secure",
                    usage: "single_use",
                  },
                  source_transfer: null,
                  statement_descriptor: null,
                  statement_descriptor_suffix: null,
                  status: "succeeded",
                  transfer_data: null,
                  transfer_group: null,
                },
              },
              success: true,
            },
          ],
          has_more: false,
          total_count: 1,
        });
      }, 300);
    });
  } else if (status === "failed") {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          data: [
            {
              id: "req_2udDOR9ADI8nIT",
              application: null,
              created: 1619260447,
              livemode: true,
              objects: null,
              request: {
                livemode: true,
                method: "POST",
                url: "/v1/charges",
                get_params: {},
                post_params: {
                  card: {
                    number: "***********7084",
                    exp_month: "5",
                    exp_year: "2024",
                    name: "Rap Molina",
                  },
                  amount: "225000",
                  currency: "php",
                  description: "Magpie load Dibz wallet",
                  ip: "127.0.0.1",
                  external_id: "DQEMJqKdmnG1H2FQiPL3R0CrtBI",
                  payment_user_agent:
                    "Stripe/v1 ActiveMerchantBindings/1.119.0",
                  statement_descriptor: "Dibztech Inc.",
                  metadata: {
                    connect_agent: "Spreedly",
                    order_id: "DQEMJqKdmnG1H2FQiPL3R0CrtBI",
                  },
                },
                query_string: "",
                request_body:
                  "card[number]=***********7084\u0026card[exp_month]=5\u0026card[exp_year]=2024\u0026card[name]=Rap+Molina\u0026amount=225000\u0026currency=php\u0026description=Magpie+load+Dibz+wallet\u0026ip=127.0.0.1\u0026external_id=DQEMJqKdmnG1H2FQiPL3R0CrtBI\u0026payment_user_agent=Stripe%2Fv1+ActiveMerchantBindings%2F1.119.0\u0026statement_descriptor=Dibztech+Inc.\u0026metadata[connect_agent]=Spreedly\u0026metadata[order_id]=DQEMJqKdmnG1H2FQiPL3R0CrtBI",
                ip: "54.204.55.35",
                headers: {
                  "User-Agent": "Stripe/v1 ActiveMerchantBindings/1.119.0",
                  "Stripe-Version": "2015-04-07",
                  "X-Tls-Version": "TLSv1.2",
                },
                client_ip: "127.0.0.1",
                from_dashboard: false,
                from_dashboard_user: null,
                origin: null,
              },
              response: {
                status: 402,
                headers: {
                  "Content-Type": "application/json",
                  "Stripe-Version": "2015-04-07",
                  "Content-Length": "266",
                  "Request-Id": "req_2udDOR9ADI8nIT",
                },
                body: {
                  error: {
                    charge: "ch_1IjioNIoBfvEUBiwjep6BeWM",
                    code: "card_declined",
                    decline_code: "do_not_honor",
                    doc_url:
                      "https://stripe.com/docs/error-codes/card-declined",
                    message: "Your card was declined.",
                    type: "card_error",
                  },
                },
              },
              success: false,
            },
          ],
          has_more: false,
          total_count: 1,
        });
      }, 300);
    });
  } else if (status === "uncaptured") {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          data: [
            {
              id: "req_I25HUSHf18Y3fa",
              application: null,
              created: 1619260529,
              livemode: true,
              objects: null,
              request: {
                livemode: true,
                method: "POST",
                url: "/v1/charges",
                get_params: {},
                post_params: {
                  source: "src_1Ijip9IoBfvEUBiwBtenf3eF",
                  capture: "false",
                  amount: "3000",
                  currency: "php",
                  ip: "127.0.0.1",
                  external_id: "QIjzaOQBdYSweIMDaQgoSKSVIsf",
                  payment_user_agent:
                    "Stripe/v1 ActiveMerchantBindings/1.119.0",
                  metadata: {
                    connect_agent: "Spreedly",
                    order_id: "QIjzaOQBdYSweIMDaQgoSKSVIsf",
                  },
                },
                query_string: "",
                request_body:
                  "source=src_1Ijip9IoBfvEUBiwBtenf3eF\u0026capture=false\u0026amount=3000\u0026currency=php\u0026ip=127.0.0.1\u0026external_id=QIjzaOQBdYSweIMDaQgoSKSVIsf\u0026payment_user_agent=Stripe%2Fv1+ActiveMerchantBindings%2F1.119.0\u0026metadata[connect_agent]=Spreedly\u0026metadata[order_id]=QIjzaOQBdYSweIMDaQgoSKSVIsf",
                ip: "54.204.55.35",
                headers: {
                  "User-Agent": "Stripe/v1 ActiveMerchantBindings/1.119.0",
                  "Stripe-Version": "2015-04-07",
                  "X-Tls-Version": "TLSv1.2",
                },
                client_ip: "127.0.0.1",
                from_dashboard: false,
                from_dashboard_user: null,
                origin: null,
              },
              response: {
                status: 200,
                headers: {
                  "Content-Type": "application/json",
                  "Stripe-Version": "2015-04-07",
                  "Idempotency-Key": "QIjzaOQBdYSweIMDaQgoSKSVIsf",
                  "Original-Request": "req_I25HUSHf18Y3fa",
                  "Content-Length": "4329",
                  "Stripe-Should-Retry": "false",
                  "Request-Id": "req_I25HUSHf18Y3fa",
                },
                body: {
                  id: "ch_1IjipiIoBfvEUBiwTbVq2jgW",
                  object: "charge",
                  amount: 3000,
                  amount_captured: 0,
                  amount_refunded: 0,
                  application: null,
                  application_fee: null,
                  application_fee_amount: null,
                  balance_transaction: null,
                  billing_details: {
                    address: {
                      city: null,
                      country: null,
                      line1: null,
                      line2: null,
                      postal_code: null,
                      state: null,
                    },
                    email: null,
                    name: "Dominick Danao",
                    phone: null,
                  },
                  calculated_statement_descriptor: "MAGPIE.IM",
                  captured: false,
                  created: 1619260530,
                  currency: "php",
                  customer: null,
                  description: null,
                  destination: null,
                  dispute: null,
                  disputed: false,
                  failure_code: null,
                  failure_message: null,
                  fraud_details: {},
                  invoice: null,
                  livemode: true,
                  metadata: {
                    connect_agent: "Spreedly",
                    order_id: "QIjzaOQBdYSweIMDaQgoSKSVIsf",
                  },
                  on_behalf_of: null,
                  order: null,
                  outcome: {
                    network_status: "approved_by_network",
                    reason: null,
                    risk_level: "normal",
                    seller_message: "Payment complete.",
                    type: "authorized",
                  },
                  paid: true,
                  payment_intent: null,
                  payment_method: "src_1Ijip8IoBfvEUBiwfnIAhhY6",
                  payment_method_details: {
                    card: {
                      brand: "mastercard",
                      checks: {
                        address_line1_check: null,
                        address_postal_code_check: null,
                        cvc_check: "pass",
                      },
                      country: "PH",
                      exp_month: 3,
                      exp_year: 2024,
                      fingerprint: "faOth6prfJ331Cjm",
                      funding: "credit",
                      installments: null,
                      last4: "0455",
                      network: "mastercard",
                      three_d_secure: {
                        authenticated: true,
                        authentication_flow: "challenge",
                        result: "authenticated",
                        result_reason: null,
                        succeeded: true,
                        version: "1.0.2",
                      },
                      wallet: null,
                    },
                    type: "card",
                  },
                  receipt_email: null,
                  receipt_number: null,
                  receipt_url:
                    "https://pay.stripe.com/receipts/acct_173PceIoBfvEUBiw/ch_1IjipiIoBfvEUBiwTbVq2jgW/rcpt_JMRjoE3DElkf2Xax08E37UEhecqCuDC",
                  refunded: false,
                  refunds: {
                    object: "list",
                    data: [],
                    has_more: false,
                    total_count: 0,
                    url: "/v1/charges/ch_1IjipiIoBfvEUBiwTbVq2jgW/refunds",
                  },
                  review: null,
                  shipping: null,
                  source: {
                    id: "src_1Ijip9IoBfvEUBiwBtenf3eF",
                    object: "source",
                    amount: 3000,
                    client_secret: "src_client_secret_dtzWcSjrLyJRLvaYYZ1Artii",
                    created: 1619260531,
                    currency: "php",
                    flow: "redirect",
                    livemode: true,
                    metadata: {},
                    owner: {
                      address: {
                        city: null,
                        country: null,
                        line1: null,
                        line2: null,
                        postal_code: null,
                        state: null,
                      },
                      email: null,
                      name: "Dominick Danao",
                      phone: null,
                      verified_address: null,
                      verified_email: null,
                      verified_name: null,
                      verified_phone: null,
                    },
                    redirect: {
                      failure_reason: null,
                      return_url:
                        "https://core.spreedly.com/transaction/QIjzaOQBdYSweIMDaQgoSKSVIsf/redirect",
                      status: "succeeded",
                      url: "https://hooks.stripe.com/redirect/authenticate/src_1Ijip9IoBfvEUBiwBtenf3eF?client_secret=src_client_secret_dtzWcSjrLyJRLvaYYZ1Artii\u0026source_redirect_slug=live_YWNjdF8xNzNQY2VJb0JmdkVVQml3LF9KTVJpTW5OdVlzYTJRcTZiMzNoSHpXRWhRUm1aZG810100IvGnfuYD",
                    },
                    statement_descriptor: null,
                    status: "consumed",
                    three_d_secure: {
                      card: "src_1Ijip8IoBfvEUBiwfnIAhhY6",
                      exp_month: 3,
                      exp_year: 2024,
                      last4: "0455",
                      country: "PH",
                      brand: "MasterCard",
                      cvc_check: "pass",
                      funding: "credit",
                      fingerprint: "faOth6prfJ331Cjm",
                      three_d_secure: "optional",
                      authenticated: true,
                      customer: null,
                      name: null,
                      address_line1_check: null,
                      address_zip_check: null,
                      tokenization_method: null,
                      dynamic_last4: null,
                    },
                    type: "three_d_secure",
                    usage: "single_use",
                  },
                  source_transfer: null,
                  statement_descriptor: null,
                  statement_descriptor_suffix: null,
                  status: "succeeded",
                  transfer_data: null,
                  transfer_group: null,
                },
              },
              success: true,
            },
          ],
          has_more: false,
          total_count: 1,
        });
      }, 300);
    });
  } else if (status === "refunded") {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          data: [
            {
              id: "req_Wysz7RKsTXc8CY",
              application: null,
              created: 1619260359,
              livemode: true,
              objects: null,
              request: {
                livemode: true,
                method: "POST",
                url: "/v1/charges/ch_1IjimtIoBfvEUBiwmkQTu5UR/refunds",
                get_params: {},
                post_params: {
                  metadata: {
                    connect_agent: "Spreedly",
                  },
                  expand: {
                    "0": "charge",
                  },
                },
                query_string: "",
                request_body:
                  "metadata[connect_agent]=Spreedly\u0026expand[0]=charge",
                ip: "54.204.55.35",
                headers: {
                  "User-Agent": "Stripe/v1 ActiveMerchantBindings/1.119.0",
                  "Stripe-Version": "2015-04-07",
                  "X-Tls-Version": "TLSv1.2",
                },
                client_ip: "127.0.0.1",
                from_dashboard: false,
                from_dashboard_user: null,
                origin: null,
              },
              response: {
                status: 200,
                headers: {
                  "Content-Type": "application/json",
                  "Stripe-Version": "2015-04-07",
                  "Content-Length": "4305",
                  "Request-Id": "req_Wysz7RKsTXc8CY",
                },
                body: {
                  id: "re_1IjimxIoBfvEUBiwfCVoQ1y8",
                  object: "refund",
                  amount: 5000,
                  balance_transaction: "txn_1IjimxIoBfvEUBiwf7EIe2PU",
                  charge: {
                    id: "ch_1IjimtIoBfvEUBiwmkQTu5UR",
                    object: "charge",
                    amount: 5000,
                    amount_captured: 5000,
                    amount_refunded: 5000,
                    application: null,
                    application_fee: null,
                    application_fee_amount: null,
                    balance_transaction: "txn_1IjimuIoBfvEUBiwuTJ3JDw6",
                    billing_details: {
                      address: {
                        city: null,
                        country: null,
                        line1: null,
                        line2: null,
                        postal_code: null,
                        state: null,
                      },
                      email: null,
                      name: "Rap Molina",
                      phone: null,
                    },
                    calculated_statement_descriptor: "DIBZTECH INC.",
                    captured: true,
                    created: 1619260355,
                    currency: "php",
                    customer: null,
                    description: "Charge 50php. This will be refunded.",
                    destination: null,
                    dispute: null,
                    disputed: false,
                    failure_code: null,
                    failure_message: null,
                    fraud_details: {},
                    invoice: null,
                    livemode: true,
                    metadata: {
                      connect_agent: "Spreedly",
                      order_id: "HQwQWX6bg1CBQ58qAEecNByqDen",
                    },
                    on_behalf_of: null,
                    order: null,
                    outcome: {
                      network_status: "approved_by_network",
                      reason: null,
                      risk_level: "normal",
                      seller_message: "Payment complete.",
                      type: "authorized",
                    },
                    paid: true,
                    payment_intent: null,
                    payment_method: "card_1IjimtIoBfvEUBiwCT4paZTf",
                    payment_method_details: {
                      card: {
                        brand: "mastercard",
                        checks: {
                          address_line1_check: null,
                          address_postal_code_check: null,
                          cvc_check: "pass",
                        },
                        country: "PH",
                        exp_month: 9,
                        exp_year: 2026,
                        fingerprint: "MUVwFSDYD0l3ZatT",
                        funding: "debit",
                        installments: null,
                        last4: "4284",
                        network: "mastercard",
                        three_d_secure: null,
                        wallet: null,
                      },
                      type: "card",
                    },
                    receipt_email: null,
                    receipt_number: null,
                    receipt_url:
                      "https://pay.stripe.com/receipts/acct_173PceIoBfvEUBiw/ch_1IjimtIoBfvEUBiwmkQTu5UR/rcpt_JMRgX2EIIwgYeyo6o2sDhMh2CjGqT1C",
                    refunded: true,
                    refunds: {
                      object: "list",
                      data: [
                        {
                          id: "re_1IjimxIoBfvEUBiwfCVoQ1y8",
                          object: "refund",
                          amount: 5000,
                          balance_transaction: "txn_1IjimxIoBfvEUBiwf7EIe2PU",
                          charge: "ch_1IjimtIoBfvEUBiwmkQTu5UR",
                          created: 1619260359,
                          currency: "php",
                          metadata: {
                            connect_agent: "Spreedly",
                          },
                          payment_intent: null,
                          reason: null,
                          receipt_number: null,
                          source_transfer_reversal: null,
                          status: "succeeded",
                          transfer_reversal: null,
                        },
                      ],
                      has_more: false,
                      total_count: 1,
                      url: "/v1/charges/ch_1IjimtIoBfvEUBiwmkQTu5UR/refunds",
                    },
                    review: null,
                    shipping: null,
                    source: {
                      id: "card_1IjimtIoBfvEUBiwCT4paZTf",
                      object: "card",
                      address_city: null,
                      address_country: null,
                      address_line1: null,
                      address_line1_check: null,
                      address_line2: null,
                      address_state: null,
                      address_zip: null,
                      address_zip_check: null,
                      brand: "MasterCard",
                      country: "PH",
                      customer: null,
                      cvc_check: "pass",
                      dynamic_last4: null,
                      exp_month: 9,
                      exp_year: 2026,
                      fingerprint: "MUVwFSDYD0l3ZatT",
                      funding: "debit",
                      last4: "4284",
                      metadata: {},
                      name: "Rap Molina",
                      tokenization_method: null,
                    },
                    source_transfer: null,
                    statement_descriptor: "Dibztech Inc.",
                    statement_descriptor_suffix: null,
                    status: "succeeded",
                    transfer_data: null,
                    transfer_group: null,
                  },
                  created: 1619260359,
                  currency: "php",
                  metadata: {
                    connect_agent: "Spreedly",
                  },
                  payment_intent: null,
                  reason: null,
                  receipt_number: null,
                  source_transfer_reversal: null,
                  status: "succeeded",
                  transfer_reversal: null,
                },
              },
              success: true,
            },
            {
              id: "req_Seo3XJf7LFMB0I",
              application: null,
              created: 1619260355,
              livemode: true,
              objects: null,
              request: {
                livemode: true,
                method: "POST",
                url: "/v1/charges",
                get_params: {},
                post_params: {
                  card: {
                    number: "************4284",
                    exp_month: "9",
                    exp_year: "2026",
                    cvc: "***",
                    name: "Rap Molina",
                  },
                  amount: "5000",
                  currency: "php",
                  description: "Charge 50php. This will be refunded.",
                  ip: "127.0.0.1",
                  external_id: "HQwQWX6bg1CBQ58qAEecNByqDen",
                  payment_user_agent:
                    "Stripe/v1 ActiveMerchantBindings/1.119.0",
                  statement_descriptor: "Dibztech Inc.",
                  metadata: {
                    connect_agent: "Spreedly",
                    order_id: "HQwQWX6bg1CBQ58qAEecNByqDen",
                  },
                },
                query_string: "",
                request_body:
                  "card[number]=************4284\u0026card[exp_month]=9\u0026card[exp_year]=2026\u0026card[cvc]=%2A%2A%2A\u0026card[name]=Rap+Molina\u0026amount=5000\u0026currency=php\u0026description=Charge+50php.+This+will+be+refunded.\u0026ip=127.0.0.1\u0026external_id=HQwQWX6bg1CBQ58qAEecNByqDen\u0026payment_user_agent=Stripe%2Fv1+ActiveMerchantBindings%2F1.119.0\u0026statement_descriptor=Dibztech+Inc.\u0026metadata[connect_agent]=Spreedly\u0026metadata[order_id]=HQwQWX6bg1CBQ58qAEecNByqDen",
                ip: "54.204.55.35",
                headers: {
                  "User-Agent": "Stripe/v1 ActiveMerchantBindings/1.119.0",
                  "Stripe-Version": "2015-04-07",
                  "X-Tls-Version": "TLSv1.2",
                },
                client_ip: "127.0.0.1",
                from_dashboard: false,
                from_dashboard_user: null,
                origin: null,
              },
              response: {
                status: 200,
                headers: {
                  "Content-Type": "application/json",
                  "Stripe-Version": "2015-04-07",
                  "Content-Length": "3066",
                  "Request-Id": "req_Seo3XJf7LFMB0I",
                },
                body: {
                  id: "ch_1IjimtIoBfvEUBiwmkQTu5UR",
                  object: "charge",
                  amount: 5000,
                  amount_captured: 5000,
                  amount_refunded: 0,
                  application: null,
                  application_fee: null,
                  application_fee_amount: null,
                  balance_transaction: "txn_1IjimuIoBfvEUBiwuTJ3JDw6",
                  billing_details: {
                    address: {
                      city: null,
                      country: null,
                      line1: null,
                      line2: null,
                      postal_code: null,
                      state: null,
                    },
                    email: null,
                    name: "Rap Molina",
                    phone: null,
                  },
                  calculated_statement_descriptor: "DIBZTECH INC.",
                  captured: true,
                  created: 1619260355,
                  currency: "php",
                  customer: null,
                  description: "Charge 50php. This will be refunded.",
                  destination: null,
                  dispute: null,
                  disputed: false,
                  failure_code: null,
                  failure_message: null,
                  fraud_details: {},
                  invoice: null,
                  livemode: true,
                  metadata: {
                    connect_agent: "Spreedly",
                    order_id: "HQwQWX6bg1CBQ58qAEecNByqDen",
                  },
                  on_behalf_of: null,
                  order: null,
                  outcome: {
                    network_status: "approved_by_network",
                    reason: null,
                    risk_level: "normal",
                    seller_message: "Payment complete.",
                    type: "authorized",
                  },
                  paid: true,
                  payment_intent: null,
                  payment_method: "card_1IjimtIoBfvEUBiwCT4paZTf",
                  payment_method_details: {
                    card: {
                      brand: "mastercard",
                      checks: {
                        address_line1_check: null,
                        address_postal_code_check: null,
                        cvc_check: "pass",
                      },
                      country: "PH",
                      exp_month: 9,
                      exp_year: 2026,
                      fingerprint: "MUVwFSDYD0l3ZatT",
                      funding: "debit",
                      installments: null,
                      last4: "4284",
                      network: "mastercard",
                      three_d_secure: null,
                      wallet: null,
                    },
                    type: "card",
                  },
                  receipt_email: null,
                  receipt_number: null,
                  receipt_url:
                    "https://pay.stripe.com/receipts/acct_173PceIoBfvEUBiw/ch_1IjimtIoBfvEUBiwmkQTu5UR/rcpt_JMRgX2EIIwgYeyo6o2sDhMh2CjGqT1C",
                  refunded: false,
                  refunds: {
                    object: "list",
                    data: [],
                    has_more: false,
                    total_count: 0,
                    url: "/v1/charges/ch_1IjimtIoBfvEUBiwmkQTu5UR/refunds",
                  },
                  review: null,
                  shipping: null,
                  source: {
                    id: "card_1IjimtIoBfvEUBiwCT4paZTf",
                    object: "card",
                    address_city: null,
                    address_country: null,
                    address_line1: null,
                    address_line1_check: null,
                    address_line2: null,
                    address_state: null,
                    address_zip: null,
                    address_zip_check: null,
                    brand: "MasterCard",
                    country: "PH",
                    customer: null,
                    cvc_check: "pass",
                    dynamic_last4: null,
                    exp_month: 9,
                    exp_year: 2026,
                    fingerprint: "MUVwFSDYD0l3ZatT",
                    funding: "debit",
                    last4: "4284",
                    metadata: {},
                    name: "Rap Molina",
                    tokenization_method: null,
                  },
                  source_transfer: null,
                  statement_descriptor: "Dibztech Inc.",
                  statement_descriptor_suffix: null,
                  status: "succeeded",
                  transfer_data: null,
                  transfer_group: null,
                },
              },
              success: true,
            },
          ],
          has_more: false,
          total_count: 2,
        });
      }, 300);
    });
  } else {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          data: [],
          has_more: false,
          total_count: 0,
        });
      }, 300);
    });
  }
};

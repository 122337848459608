import { PayoutDetailsPayloadType } from "@custom-types/Onboarding";
import {
  TeamApiRequestType,
  TeamsResponseType,
  TeamType,
} from "@custom-types/Organization";
import {
  TeamInvitesResponseType,
  TeamMemberInviteResponseType,
  TeamMembersResponseType,
} from "@custom-types/Teams";
import { ApiResponse } from "apisauce";
import { String } from "aws-sdk/clients/apigateway";
import { createApiClient, createDashboardClient } from "./client";
import URIs, { DASHBOARD_API_KEY } from "./uri";

export const getCurrentTeam = async (
  token: string
): Promise<ApiResponse<any, any>> => {
  const api = createApiClient();
  return await api.get(
    URIs.CURRENT_TEAM,
    {},
    { headers: { Authorization: `Bearer ${token}` } }
  );
};

export const updateTeam = async (
  token: string,
  params: TeamApiRequestType
): Promise<ApiResponse<any, any>> => {
  const api = createApiClient();
  return await api.patch(URIs.UPDATE_TEAM, params, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getTeams = async (
  token: string
): Promise<ApiResponse<any, any>> => {
  const api = createApiClient();
  return await api.get(
    URIs.TEAMS,
    {},
    { headers: { Authorization: `Bearer ${token}` } }
  );
};

export const getTeamMembers = async (
  token: string,
  query?: string,
  role?: string,
): Promise<ApiResponse<any, any>> => {
  const api = createApiClient();
  return await api.get(
    URIs.TEAM_MEMBERS,
    { query, role },
    { headers: { Authorization: `Bearer ${token}` } }
  );
};

export const inviteMembers = async (
  token: string,
  emails: string[],
  role: string
): Promise<ApiResponse<any, any>> => {
  const api = createApiClient();
  return await api.post(
    URIs.INVITE_MEMBERS,
    {
      emails,
      role,
    },
    { headers: { Authorization: `Bearer ${token}` } }
  );
};

export const getInvitations = async (
  token: string
): Promise<ApiResponse<any, any>> => {
  const api = createApiClient();
  return await api.get(
    URIs.TEAM_INVITATIONS,
    {},
    { headers: { Authorization: `Bearer ${token}` } }
  );
};

export const getInvitationDetails = async (
  token: string,
  code: string,
): Promise<ApiResponse<any, any>> => {
  const api = createApiClient();
  return await api.get(
    URIs.INVITATION_DETAILS.replaceAll('$1', code),
    {},
    { headers: { Authorization: `Bearer ${token}` } }
  );
};

export const acceptInvitation = async (
  token: string,
  code: String
): Promise<ApiResponse<any, any>> => {
  const api = createApiClient();
  return await api.post(
    URIs.ACCEPT_INVITATION.replace("$1", code),
    {},
    { headers: { Authorization: `Bearer ${token}` } }
  );
};

export const deleteInvitation = async (
  token: string,
  id: number
): Promise<ApiResponse<any, any>> => {
  const api = createApiClient();
  return await api.delete(
    URIs.INVITATION_DETAILS.replace("$1", id.toString()),
    {},
    { headers: { Authorization: `Bearer ${token}` } }
  );
};

export const resendInvitation = async (
  token: string,
  id: number
): Promise<any> => {
  const api = createApiClient();
  const response = await api.post<any>(
    URIs.RESEND_INVITATION.replace("$1", id.toString()),
    {},
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return response.data;
};

export const updateTeamMember = async (
  token: string,
  id: number,
  role: string
): Promise<ApiResponse<any, any>> => {
  const api = createApiClient();
  return await api.patch(
    URIs.UPDATE_TEAM_MEMBER.replace("$1", id.toString()),
    {
      role,
    },
    { headers: { Authorization: `Bearer ${token}` } }
  );
};

export const sendChangeRoleEmail = async (
  email: string,
  organization: string,
  admin_email: string,
  old_role: string,
  new_role: string,
): Promise<ApiResponse<any, any>> => {
  const api = createDashboardClient();
  return await api.post(
    URIs.CHANGE_ROLE_EMAIL,
    { 
      email,
      organization,
      admin_email,
      old_role,
      new_role
    },
    { headers: { Authorization: DASHBOARD_API_KEY } }
  );
};

export const removeTeamMember = async (
  token: string,
  id: number
): Promise<ApiResponse<any, any>> => {
  const api = createApiClient();
  return await api.delete(
    URIs.REMOVE_TEAM_MEMBER.replace("$1", id.toString()),
    {},
    { headers: { Authorization: `Bearer ${token}` } }
  );
};

export const switchTeam = async (
  token: string,
  id: number
): Promise<ApiResponse<any, any>> => {
  const api = createApiClient();
  return await api.post(
    URIs.SWITCH_TEAM.replace("$1", id.toString()),
    {},
    { headers: { Authorization: `Bearer ${token}` } }
  );
};

export const updateBankDetails = async (
  token: string,
  params: PayoutDetailsPayloadType
): Promise<ApiResponse<any, any>> => {
  const api = createApiClient();
  return await api.patch(URIs.UPDATE_TEAM, { payout_settings: params }, {
    headers: { Authorization: `Bearer ${token}` },
  });
};


export const getPaymentMethodSettings = async (
  token: string,
): Promise<ApiResponse<any, any>> => {
  const api = createApiClient();
  return await api.get(
    URIs.PAYMENT_METHOD_SETTINGS,
    {},
    { headers: { Authorization: `Bearer ${token}` } }
  );
};
import { BatchRequestItemPayloadType, BatchRequestPersonType, BatchRequestUpdatePayloadType, PaymentRequestPayloadType } from "@custom-types/PaymentRequest";
import { ApiResponse } from "apisauce";
import { createApiClient, createDashboardClient, createPaymentRequestClient } from "./client";
import URIs, { DASHBOARD_API_KEY } from "./uri";



export const getPaymentRequests = async (
  token: string,
  status?: string,
  url?: string,
  batch_id?: string
): Promise<ApiResponse<any, any>> => {
  const api = createApiClient();
  if (url) {
    api.setBaseURL(url);
    return await api.get('', undefined, {
      headers: { Authorization: `Bearer ${token}` },
    });
  } else {
    const params: {status?: string, batch_id?: string} = {};
    if (status) {
      params.status = status;
    }
    if (batch_id) {
      params.batch_id = batch_id;
    }
    return await api.get(URIs.LIST_PAYMENT_REQUESTS, params, {
      headers: { Authorization: `Bearer ${token}` },
    });
  }
};

export const createPaymentRequest = async (
  key: string,
  params: PaymentRequestPayloadType,
): Promise<ApiResponse<any, any>> => {
  const api = createPaymentRequestClient(key);
  return await api.post(URIs.PAYMENT_REQUESTS, params);
};

export const getPaymentRequestDetails = async (
  token: string,
  requestId: string,
): Promise<ApiResponse<any, any>> => {
  const api = createApiClient()
  return await api.get(URIs.GET_PAYMENT_REQUEST.replace("$1", requestId), undefined, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

/* export const getPaymentRequestDetails = async (
  key: string,
  requestId: string,
): Promise<ApiResponse<any, any>> => {
  const api = createPaymentRequestClient(key);
  return await api.get(URIs.PAYMENT_REQUEST.replace("$1", requestId));
}; */

export const updatePaymentRequestMetadata = async (
  key: string,
  requestId: string,
  metadata: any,
): Promise<ApiResponse<any, any>> => {
  const api = createPaymentRequestClient(key);
  return await api.put(URIs.PAYMENT_REQUEST.replace("$1", requestId), {metadata});
};

export const voidPaymentRequest = async (
  key: string,
  requestId: string,
  reason: string,
): Promise<ApiResponse<any, any>> => {
  const api = createPaymentRequestClient(key);
  return await api.post(URIs.VOID_REQUEST.replace("$1", requestId), {reason});
};

export const resendPaymentRequest = async (
  key: string,
  requestId: string,
): Promise<ApiResponse<any, any>> => {
  const api = createPaymentRequestClient(key);
  return await api.post(URIs.RESEND_REQUEST.replace("$1", requestId));
};

export const uploadImage = async (
  organizationId: string,
  image: File
): Promise<ApiResponse<any, any>> => {
  const api = createDashboardClient();
  const formData = new FormData();
  formData.append("organization_id", organizationId);
  formData.append("products", image);

  return await api.post(URIs.UPLOAD_LOGO, formData, {
    headers: { Authorization: DASHBOARD_API_KEY },
  });
};

export const deleteImage = async (
  organizationId: string,
  url: string
): Promise<ApiResponse<any, any>> => {
  const api = createDashboardClient();

  return await api.delete(URIs.DELETE_FILE, {
    organization_id: organizationId,
    url 
   }, {
    headers: { Authorization: DASHBOARD_API_KEY },
  });
};

export const exportRequests = async (
  key: string,
  from?: string,
  to?: string,
  status?: string,
): Promise<ApiResponse<any, any>> => {
  const api = createPaymentRequestClient(key);
  return await api.post(URIs.EXPORT_REQUESTS, {
    from,
    to,
    status,
  });
};

export const createBatchRequest = async (
  key: string,
  name: string,
  author: BatchRequestPersonType
): Promise<ApiResponse<any, any>> => {
  const api = createPaymentRequestClient(key);
  return await api.post(URIs.BATCH_PAYMENT_REQUESTS, { name, author });
};

export const getBatches = async (
  key: string,
  status?: string,
  url?: string,
): Promise<ApiResponse<any, any>> => {
  const api = createPaymentRequestClient(key);
  if (url) {
    api.setBaseURL(url);
    return await api.get('');
  } else {
    const params: {status?: string, batch_id?: string} = {};
    if (status) {
      params.status = status;
    }
    return await api.get(URIs.BATCH_PAYMENT_REQUESTS, params);
  }
};

export const getBatchRequests = async (
  key: string,
  status?: string,
  url?: string,
  batch_id?: string,
): Promise<ApiResponse<any, any>> => {
  const api = createPaymentRequestClient(key);
  if (url) {
    api.setBaseURL(url);
    return await api.get('');
  } else {
    const params: {status?: string, batch_id?: string} = {};
    if (batch_id) {
      params.batch_id = batch_id;
    }
    if (status) {
      params.status = status;
    }
    return await api.get(URIs.PAYMENT_REQUESTS, params);
  }
  
};

export const getBatchRequestDetails = async (
  key: string,
  batchId: string,
): Promise<ApiResponse<any, any>> => {
  const api = createPaymentRequestClient(key);
  return await api.get(URIs.BATCH_PAYMENT_REQUEST.replace("$1", batchId));
};

export const updateBatchRequest = async (
  key: string,
  batchId: string,
  params: BatchRequestUpdatePayloadType,
): Promise<ApiResponse<any, any>> => {
  const api = createPaymentRequestClient(key);
  return await api.put(URIs.BATCH_PAYMENT_REQUEST.replace("$1", batchId), params);
};

export const cancelBatchRequest = async (
  key: string,
  batchId: string,
): Promise<ApiResponse<any, any>> => {
  const api = createPaymentRequestClient(key);
  return await api.post(URIs.CANCEL_BATCH_PAYMENT_REQUEST.replace("$1", batchId), {});
};

export const createBatchPaymentRequest = async (
  key: string,
  batchId: string,
  params: BatchRequestItemPayloadType,
): Promise<ApiResponse<any, any>> => {
  const api = createPaymentRequestClient(key);
  return await api.post(URIs.CREATE_BATCH_PAYMENT_REQUEST_ITEM.replace("$1", batchId), params);
};

export const uploadBatchFile = async (
  organizationId: string,
  file: File
): Promise<ApiResponse<any, any>> => {
  const api = createDashboardClient();
  const formData = new FormData();
  formData.append("organization_id", organizationId);
  formData.append("file", file);

  return await api.post(URIs.UPLOAD_BATCH, formData, {
    headers: { Authorization: DASHBOARD_API_KEY },
  });
};
import {
  fetchClientInfo,
  register,
  login,
  login2fa,
  loginResend,
  logout,
} from "./auth";
import {
  getSession,
  updateSession,
  me,
  updateMe,
  changeEnvironment,
  changePassword,
  sendPasswordChangedEmail,
  sendVerificationEmail,
  verifyEmail,
  sendForgotPasswordEmail,
  forgotPassword,
  resetPassword,
  uploadLogo,
  send2faActivatedEmail,
  deactivate2faSMS,
  deactivate2faAuthenticator,
  send2faDeactivatedEmail,
} from "./account";
import {
  getOnboardingDetails,
  saveBusinessStructure,
  saveRepresentativeDetails,
  saveBusinessDetails,
  saveBusinessDocuments,
  uploadDocument,
  deleteDocument,
  saveCreditCardStatement,
  saveBankDetails,
  saveStatementOfAcceptance,
  getOwners,
  addOwner,
  removeOwner,
  activate2faSMS,
  verify2faSMS,
  activate2faAuthenticator,
  verify2faAuthenticator,
  submitOnboarding,
} from "./onboarding";
import {
  getPayments,
  exportPayments,
  getPaymentDetails,
  refundPayment,
  voidPayment,
  sendReceipt,
  sendRefundReceipt,
  getPaymentEvents,
  getPaymentReceiptEmailHistory,
  getPaymentLogs,
} from "./payments";

import {
  getPaymentRequests,
  getPaymentRequestDetails,
  createPaymentRequest,
  updatePaymentRequestMetadata,
  voidPaymentRequest,
  resendPaymentRequest,
  uploadImage,
  deleteImage,
  exportRequests,
  createBatchRequest,
  getBatches,
  getBatchRequests,
  getBatchRequestDetails,
  updateBatchRequest,
  cancelBatchRequest,
  createBatchPaymentRequest,
  uploadBatchFile,
} from "./payment-requests";

import {
  getPaymentLinks,
  getPaymentLinkDetails,
  createPaymentLink,
  updatePaymentLink,
  deactivatePaymentLink,
  activatePaymentLink,
  getPaymentLinkTransactions,
} from './payment-links';

import {
  getCurrentTeam,
  updateTeam,
  getTeams,
  getTeamMembers,
  getInvitations,
  getInvitationDetails,
  acceptInvitation,
  deleteInvitation,
  inviteMembers,
  resendInvitation,
  updateTeamMember,
  sendChangeRoleEmail,
  removeTeamMember,
  switchTeam,
  updateBankDetails,
  getPaymentMethodSettings,
} from "./team";

import {
  getCustomers,
  createCustomer,
  getCustomerDetails,
  updateCustomer,
  getCustomerPayments,
} from "./customers";

import { sendWelcomeEmail, getDeveloperKeys } from "./settings";

import {
  getPayouts,
  getPayoutDetails,
  getPayoutTransactions,
  getPayoutEvents,
} from "./payouts";

import {
  getComparisonData,
  getTodayData,
  getGrossVolumeData,
  getGrossVolumeComparisonData,
  getNetVolumeData,
  getNetVolumeComparisonData,
  getSuccessfulChargeCountData,
  getSuccessfulChargeCountComparisonData,
  getRequestVolumeData,
  getRequestVolumeComparisonData,
  getLinksVolumeData,
  getLinksVolumeComparisonData,
  getLinksPaymentsCountData,
  getLinksPaymentsCountComparisonData,
} from "./dashboard";

export default {
  fetchClientInfo,
  register,
  login,
  login2fa,
  loginResend,
  logout,

  getSession,
  updateSession,
  me,
  updateMe,
  changeEnvironment,
  changePassword,
  sendPasswordChangedEmail,
  sendVerificationEmail,
  verifyEmail,
  sendForgotPasswordEmail,
  forgotPassword,
  resetPassword,
  uploadLogo,
  send2faActivatedEmail,
  deactivate2faSMS,
  deactivate2faAuthenticator,
  send2faDeactivatedEmail,

  getCurrentTeam,
  updateTeam,
  getTeams,
  getTeamMembers,
  getInvitations,
  getInvitationDetails,
  acceptInvitation,
  deleteInvitation,
  inviteMembers,
  resendInvitation,
  updateTeamMember,
  sendChangeRoleEmail,
  removeTeamMember,
  switchTeam,
  updateBankDetails,
  getPaymentMethodSettings,

  getOnboardingDetails,
  saveBusinessStructure,
  saveRepresentativeDetails,
  saveBusinessDetails,
  saveBusinessDocuments,
  uploadDocument,
  deleteDocument,
  saveCreditCardStatement,
  saveBankDetails,
  saveStatementOfAcceptance,
  getOwners,
  addOwner,
  removeOwner,
  activate2faSMS,
  verify2faSMS,
  activate2faAuthenticator,
  verify2faAuthenticator,
  submitOnboarding,

  getPayments,
  exportPayments,
  getPaymentDetails,
  refundPayment,
  voidPayment,
  sendReceipt,
  sendRefundReceipt,
  getPaymentEvents,
  getPaymentReceiptEmailHistory,
  getPaymentLogs,

  getCustomers,
  createCustomer,
  getCustomerDetails,
  updateCustomer,
  getCustomerPayments,

  getPaymentRequests,
  getPaymentRequestDetails,
  createPaymentRequest,
  updatePaymentRequestMetadata,
  voidPaymentRequest,
  resendPaymentRequest,
  uploadImage,
  deleteImage,
  exportRequests,
  createBatchRequest,
  getBatches,
  getBatchRequests,
  getBatchRequestDetails,
  updateBatchRequest,
  cancelBatchRequest,
  createBatchPaymentRequest,
  uploadBatchFile,

  getPaymentLinks,
  getPaymentLinkDetails,
  createPaymentLink,
  updatePaymentLink,
  deactivatePaymentLink,
  activatePaymentLink,
  getPaymentLinkTransactions,

  sendWelcomeEmail,
  getDeveloperKeys,

  getPayouts,
  getPayoutDetails,
  getPayoutTransactions,
  getPayoutEvents,

  getComparisonData,
  getTodayData,
  getGrossVolumeData,
  getGrossVolumeComparisonData,
  getNetVolumeData,
  getNetVolumeComparisonData,
  getSuccessfulChargeCountData,
  getSuccessfulChargeCountComparisonData,
  getRequestVolumeData,
  getRequestVolumeComparisonData,
  getLinksVolumeData,
  getLinksVolumeComparisonData,
  getLinksPaymentsCountData,
  getLinksPaymentsCountComparisonData,
};

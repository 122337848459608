import {
  PayoutDetailsPayloadType,
  BusinessDetailsPayloadType,
  BusinessDocumentsPayloadType,
  BusinessStructurePayloadType,
  CreditCardStatementPayloadType,
  OwnerPayloadType,
  RepresentativePayloadType,
  SoaPayloadType,
} from "@custom-types/Onboarding";
import { ApiResponse } from "apisauce";
import { UploadType } from "@reducers/OnboardingReducer";
import { createApiClient } from "./client";
import URIs from "./uri";

export const getOnboardingDetails = async (
  token: string
): Promise<ApiResponse<any, any>> => {
  const api = createApiClient();
  return await api.get(
    URIs.ONBOARDING,
    {},
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

export const saveBusinessStructure = async (
  token: string,
  params: BusinessStructurePayloadType
): Promise<ApiResponse<any, any>> => {
  const api = createApiClient();
  return await api.patch(
    URIs.SAVE_ONBOARDING_DATA,
    { business_structure: params },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

export const saveRepresentativeDetails = async (
  token: string,
  params: RepresentativePayloadType
): Promise<ApiResponse<any, any>> => {
  const api = createApiClient();
  return await api.patch(
    URIs.SAVE_ONBOARDING_DATA,
    { business_representative: params },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

export const saveBusinessDetails = async (
  token: string,
  params: BusinessDetailsPayloadType
): Promise<ApiResponse<any, any>> => {
  const api = createApiClient();
  return await api.patch(
    URIs.SAVE_ONBOARDING_DATA,
    { business_details: params },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

export const saveBusinessDocuments = async (
  token: string,
  params: BusinessDocumentsPayloadType
): Promise<ApiResponse<any, any>> => {
  const api = createApiClient();
  return await api.patch(
    URIs.SAVE_ONBOARDING_DATA,
    { business_documents: params },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

export const uploadDocument = async (
  token: string,
  type: UploadType,
  document: File
): Promise<ApiResponse<any, any>> => {
  const api = createApiClient();
  const formData = new FormData();
  formData.append("type", type);
  formData.append("file", document);

  return await api.post(URIs.UPLOAD_DOCUMENT, formData, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const deleteDocument = async (
  token: string,
  type: UploadType,
  documentId: string
): Promise<ApiResponse<any, any>> => {
  const api = createApiClient();

  return await api.delete(
    URIs.DELETE_DOCUMENT.replace("$1", documentId),
    {},
    {
      data: { type },
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

export const saveCreditCardStatement = async (
  token: string,
  params: CreditCardStatementPayloadType
): Promise<ApiResponse<any, any>> => {
  const api = createApiClient();
  return await api.patch(
    URIs.SAVE_ONBOARDING_DATA,
    { credit_card_statement: params },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

export const saveBankDetails = async (
  token: string,
  params: PayoutDetailsPayloadType
): Promise<ApiResponse<any, any>> => {
  const api = createApiClient();
  return await api.patch(
    URIs.SAVE_ONBOARDING_DATA,
    { payout_settings: params },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

export const saveStatementOfAcceptance = async (
  token: string,
  params: SoaPayloadType
): Promise<ApiResponse<any, any>> => {
  const api = createApiClient();
  return await api.patch(
    URIs.SAVE_ONBOARDING_DATA,
    { statement_of_acceptance: params },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

export const getOwners = async (
  token: string
): Promise<ApiResponse<any, any>> => {
  const api = createApiClient();
  return await api.get(
    URIs.OWNERS,
    {},
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

export const addOwner = async (
  token: string,
  params: OwnerPayloadType
): Promise<ApiResponse<any, any>> => {
  const api = createApiClient();
  return await api.post(URIs.OWNERS, params, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const removeOwner = async (
  token: string,
  ownerId: string
): Promise<ApiResponse<any, any>> => {
  const api = createApiClient();
  return await api.delete(
    URIs.BUSINESS_OWNER.replace("$1", ownerId),
    {},
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

export const activate2faSMS = async (
  token: string
): Promise<ApiResponse<any, any>> => {
  const api = createApiClient();
  return await api.post(
    URIs.ACTIVATE_2FA.replace("$1", "sms"),
    {},
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

export const verify2faSMS = async (
  token: string,
  pin: string
): Promise<ApiResponse<any, any>> => {
  const api = createApiClient();
  return await api.post(
    URIs.VERIFY_2FA.replace("$1", "sms"),
    { pin },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

export const activate2faAuthenticator = async (
  token: string,
  secret: string,
  backup_code: string
): Promise<ApiResponse<any, any>> => {
  const api = createApiClient();
  return await api.post(
    URIs.ACTIVATE_2FA.replace("$1", "authenticator"),
    { secret, backup_code },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

export const verify2faAuthenticator = async (
  token: string,
  pin: string
): Promise<ApiResponse<any, any>> => {
  const api = createApiClient();
  return await api.post(
    URIs.VERIFY_2FA.replace("$1", "authenticator"),
    { pin },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

export const submitOnboarding = async (
  token: string
): Promise<ApiResponse<any, any>> => {
  const api = createApiClient();
  return await api.post(
    URIs.SUBMIT_ONBOARDING,
    {},
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};
